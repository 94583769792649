import {
    PLATFORM_FACEBOOK,
    PLATFORM_PINTEREST,
    PLATFORM_SNAPCHAT,
    PLATFORM_TIKTOK
} from './globals'

const platformAspectRatios = [
    {
        name: 'square',
        ratio: '1:1',
        computedRatio: 1.00,
        imagePlatforms: [PLATFORM_FACEBOOK],
        videoPlatforms: [PLATFORM_FACEBOOK],
    },
    {
        name: 'two-by-three',
        ratio: '2:3',
        computedRatio: 0.67,
        imagePlatforms: [PLATFORM_PINTEREST],
        videoPlatforms: [PLATFORM_PINTEREST]
    },
    {
        name: 'landscape',
        ratio: '9:16',
        computedRatio: 0.56,
        imagePlatforms: [PLATFORM_FACEBOOK, PLATFORM_SNAPCHAT],
        videoPlatforms: [PLATFORM_FACEBOOK, PLATFORM_SNAPCHAT, PLATFORM_TIKTOK]
    },
    {
        name: 'portrait',
        ratio: '4:3',
        computedRatio: 1.33,
        imagePlatforms: [],
        videoPlatforms: []
    },
    {
        name: 'computer-screen',
        ratio: '16:9',
        computedRatio: 1.78,
        imagePlatforms: [],
        videoPlatforms: []
    }
];

export default platformAspectRatios;

export const platformsEligibleForAssets = (assets) => {
    const platformsToDisplay = [];
    if (!assets) {
        return false;
    }

    platformAspectRatios.forEach(platformAspectRatio => {
        assets.forEach(asset => {
            if (asset?.aspect_ratio === platformAspectRatio.ratio) {
                if (asset.asset_type_id == 1) {  // Image
                    platformAspectRatio.imagePlatforms.forEach(imagePlatform => {
                        platformsToDisplay.push(imagePlatform);
                    })
                }

                if (asset.asset_type_id == 2) { // Video
                    platformAspectRatio.videoPlatforms.forEach(videoPlatform => {
                        platformsToDisplay.push(videoPlatform);
                    })
                }
            }
        })
    })

    return [...new Set(platformsToDisplay)];
}

export const hasEligibleAssetsForPlatform = (assets, platform) => {
    const platformsToDisplay = [];
    if (!assets) {
        return false;
    }

    platformAspectRatios.forEach(platformAspectRatio => {
        assets.forEach(asset => {
            if (asset?.aspect_ratio === platformAspectRatio.ratio) {
                if (asset.asset_type_id == 1) { // Image
                    platformAspectRatio.imagePlatforms.forEach(imagePlatform => {
                        platformsToDisplay.push(imagePlatform);
                    })
                }

                if (asset.asset_type_id == 2) { // Video
                    platformAspectRatio.videoPlatforms.forEach(videoPlatform => {
                        platformsToDisplay.push(videoPlatform);
                    })
                }
            }
        })
    })

    return platformsToDisplay.includes(platform);
}

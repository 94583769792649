<template>
    <div class="icons">
        <img
            v-if="platformsToDisplay.length && platformsToDisplay.includes('tiktok')"
            :src="require('@/assets/icons/tiktok.svg')"
            alt="tiktok-icon">
        <img
            v-if="platformsToDisplay.length && platformsToDisplay.includes('pinterest')"
            :src="require('@/assets/icons/pinterest.svg')"
            alt="pinterest-icon">
        <img
            v-if="platformsToDisplay.length && platformsToDisplay.includes('snapchat')"
            :src="require('@/assets/icons/snapchat.svg')"
            alt="snapchat-icon">
        <img
            v-if="platformsToDisplay.length && platformsToDisplay.includes('facebook')"
            :src="require('@/assets/icons/facebook.svg')"
            alt="facebook-icon">
    </div>
</template>

<script>
import { platformsEligibleForAssets } from '@/helpers/platformAspectRatios.js';
export default {
    name: 'PlatformIcons',
    props: {
        platforms: {
            type: Array,
            required: false,
            default: () => []
        },
        assets: {
            type: Array,
            required: false,
            default: () => []
        },
        ad: {
            type: Object,
            required: false,
            default: () => {}
        }
    },
    data() {
        return {
            assetType: '',
            platformsToDisplay: []
        };
    },
    created() {
        if (this.platforms.length) {
            this.platformsToDisplay = this.platforms.map(platform => {
                return platform.toLowerCase();
            });
            return;
        }

        const assets = this.ad?.full_configuration?.creatives[0]?.assets || this.assets;
        this.platformsToDisplay = platformsEligibleForAssets(assets);
    }
};
</script>

<style lang="scss" scoped>
.icons{
    display: flex;
    text-align: right;
    align-items: center;
    img{
        max-width: 15px;
        max-height: 15px;
        margin: 0 3px;
    }
}
</style>

<template>
    <div class="p-4 w-100">
        <div class="flex-between">
            <div class="card-title">
                {{ aspectRatio }}
            </div>
            <platform-icons :platforms="icons" />
        </div>
        <div class="error-message">
            {{ mediaError }}
        </div>
        <img
            v-show="false"
            :ref="'image_'+aspectRatio"
            :src="tempImageUrl"
            alt=""
            class="image_preview">
        <img
            v-if="imageUrl"
            :src="imageUrl"
            alt=""
            class="image_preview">
        <loader
            v-if="imageUploading"
            label="Uploading" />
        <input
            :id="aspectRatio"
            type="file"
            @change="setImage($event)">
        <styled-button
            v-if="!imageUploading && editable"
            class="gray"
            type="button">
            <label :for="aspectRatio">
                <span v-if="!imageUrl && !imageUploading">UPLOAD</span>
                <span v-if="imageUrl && !imageUploading">CHANGE</span>
            </label>
        </styled-button>
    </div>
</template>

<script>
import Loader from '@/components/globals/Loader';
import StyledButton from '@/components/globals/StyledButton';
import PlatformIcons from '@/components/creative-library/PlatformIcons.vue';
export default {
    components: {
        Loader,
        StyledButton,
        PlatformIcons
    },
    props: {
        icons: {
            type: Array,
            default: () => [],
        },
        aspectRatio: {
            type: String,
            required: true,
        },
        asset: {
            type: Object,
            required: false,
            default: () => {},
        },
        editable: {
            type: Boolean,
            required: false,
            default: false,
        }
    },
    data() {
        return {
            imageUploading: false,
            mediaError: '',
            imageAsset: null,
            tempImageUrl: null,
            width: null,
            height: null,
            aspectRatios: {
                '1.00': '1:1',  // square
                '0.67': '2:3',  // two by three
                '0.56': '9:16', // landscape
                '1.33': '4:3',  // portrait
                '1.78': '16:9', // computer screen
            }
        };
    },
    computed: {
        imageUrl() {
            return this.asset?.url;
        }
    },
    methods: {
        deleteImage() {
            this.imageAsset = null;
        },
        allowedImageFormat(filename) {
            const ext = this.getExtension(filename);
            switch (ext.toLowerCase()) {
                case 'jpg':
                case 'jpeg':
                case 'png':
                return true;
            }
            return false;
        },
        setError(message) {
            this.mediaError = message;
            this.imageUploading = false;
        },
        async setImage(event) {
            const file = event.target.files[0];
            if (!file) {
                this.setError('No file selected');
                return;
            }

            if (!this.allowedImageFormat(file.name)) {
                this.setError('Please check the format of your image! Allowed formats are: .jpg, .jpeg or .png');
                return;
            }

            await this.loadImage(file);
            const imageEl = this.$refs['image_'+this.aspectRatio];
            this.width = imageEl.naturalWidth;
            this.height = imageEl.naturalHeight;
            const aspectRatioNum = (imageEl.naturalWidth / imageEl.naturalHeight).toFixed(2);
            if (!(this.aspectRatios[aspectRatioNum] === this.aspectRatio)) {
                this.setError(`Please upload image only with a ${this.aspectRatio} aspect ratio.`);
                this.tempImageUrl = null;
                return;
            }

            try {
                this.mediaError = '';
                await this.initUploading(file);
            } catch (error) {
                this.setError('Something wrong with uploading... please check the format of your video.');
                console.log(error);
                return;
            }
        },
        async initUploading(file) {
            let formData = new FormData();
            formData.append('file', file);
            formData.append('aspect_ratio', this.aspectRatio);
            formData.append('width', this.width);
            formData.append('height', this.height);
            try {
                this.imageUploading = true;
                this.imageAsset = null;
                const response = await this.$apiRepository.uploadMediaAsset(formData);
                this.imageAsset = response?.data;
                this.$emit('update:asset', this.imageAsset);
                this.tempImageUrl = null;
            } catch (e) {
                this.setError('An error occurred while uploading image. Please contact support.');
                console.log(e);
                return;
            }
            this.imageUploading = false;
        },
        async loadImage(file) {
            let img;
            const imageLoadPromise = new Promise(resolve => {
                img = new Image();
                img.onload = resolve;
                img.src = URL.createObjectURL(file);
            });
            await imageLoadPromise;
            this.tempImageUrl = img.src;
            return img;
        },
        getExtension(filename) {
            const parts = filename.split('.');
            return parts[parts.length - 1];
        }
    }
};
</script>

<style lang="scss" scoped>
.icons{
    display: block;
    text-align: right;
    position: relative;
    img{
        max-width: 15px;
        max-height: 15px;
        margin: 0 3px;
    }
}
.videos{
    display: flex;
}
input[type="file"]{
    display: none;
}
.error-message{
    color:red;
    font-size: 16px;
    margin-bottom: 5px;
}
.image_preview{
    max-width: 100%;
    height: auto;
}
.w-100{
    width: 100%;
}
.p-4{
    padding: 10px;
}
.images, .videos{
    margin-bottom: 30px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    label{
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top:0;
        bottom:0;
        left:0;
        right:0;
        cursor: pointer;
    }
    button{
        width:100%;
        height: 40px;
        position: relative;
    }
}
.flex-between{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.card-title{
    font-size: 18px;
}
</style>

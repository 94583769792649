<template>
    <div class="p-4 w-100">
        <div class="flex-between">
            <div class="card-title">
                {{ aspectRatio }}
            </div>
            <platform-icons :platforms="icons" />
        </div>
        <div class="error-message">
            {{ mediaError }}
        </div>
        <div class="video">
            <video
                v-show="false"
                controls>
                <source
                    :ref="'temp_video_'+aspectRatio"
                    :src="tempVideoUrl">
            </video>
        </div>
        <div class="video">
            <video
                v-if="videoUrl"
                controls>
                <source
                    :ref="'video_'+aspectRatio"
                    :src="videoUrl">
            </video>
        </div>
        <loader
            v-if="videoUploading"
            label="Uploading" />
        <input
            :id="aspectRatio"
            type="file"
            @change="setVideo($event)">
        <styled-button
            v-if="!videoUploading && editable"
            class="gray"
            type="button">
            <label :for="aspectRatio">
                <span v-if="!videoUrl && !videoUploading">UPLOAD</span>
                <span v-if="videoUrl && !videoUploading">CHANGE</span>
            </label>
        </styled-button>
    </div>
</template>

<script>
import Loader from '@/components/globals/Loader';
import StyledButton from '@/components/globals/StyledButton';
import PlatformIcons from '@/components/creative-library/PlatformIcons.vue';
export default {
    components: {
        Loader,
        StyledButton,
        PlatformIcons
    },
    props: {
        icons: {
            type: Array,
            default: () => [],
        },
        aspectRatio: {
            type: String,
            required: true,
        },
        asset: {
            type: Object,
            required: false,
            default: () => {},
        },
        editable: {
            type: Boolean,
            required: false,
            default: false,
        }
    },
    data() {
        return {
            videoUploading: false,
            mediaError: '',
            videoAsset: null,
            tempVideoUrl: null,
            width: null,
            height: null,
            duration: null,
            aspectRatios: {
                '1.00': '1:1',  // square
                '0.67': '2:3',  // not sure
                '0.56': '9:16', // landscape
                '0.80': '4:5',  // facebook video placement
                '1.33': '4:3',  // portrait
                '1.78': '16:9', // computer screen
            }
        };
    },
    computed: {
        videoUrl() {
            return this.asset?.url;
        }
    },
    methods: {
        getExtension(filename) {
            const parts = filename.split('.');
            return parts[parts.length - 1];
        },
        allowedVideoFormat(filename) {
            const ext = this.getExtension(filename);
            switch (ext.toLowerCase()) {
                case 'mov':
                case 'mp4':
                return true;
            }
            return false;
        },
        setError(message) {
            this.mediaError = message;
            this.videoUploading = false;
        },
        async setVideo(event) {
            const file = event.target.files[0];
            if (!file) {
                this.setError('No file selected');
                return;
            }

            if (!this.allowedVideoFormat(file.name)) {
                this.setError('Please check the format of your video! Allowed formats are: .mp4 or .mov');
                return;
            }

            const videoEl = this.$refs['temp_video_'+this.aspectRatio];
            videoEl.src = URL.createObjectURL(file);
            videoEl.parentElement.load();
            videoEl.parentElement.onloadedmetadata = () => {
                const width = videoEl.parentElement.videoWidth;
                const height = videoEl.parentElement.videoHeight;
                const duration = videoEl.parentElement.duration;
                this.width = width;
                this.height = height;
                this.duration = duration;
                const aspectRatioNum = (width / height).toFixed(2);
                if (!(this.aspectRatios[aspectRatioNum] === this.aspectRatio)) {
                    this.setError(`Please upload video only with a ${this.aspectRatio} aspect ratio.`);
                    this.tempVideoUrl = null;
                    return;
                }
                this.mediaError = '';
                this.initUploading(file);
            };
            videoEl.onerror = () => {
                this.setError('Something wrong with uploading... please check the format of your video.');
                this.tempVideoUrl = null;
            };
        },
        async initUploading(file) {
            let formData = new FormData();
            formData.append('file', file);
            formData.append('aspect_ratio', this.aspectRatio);
            formData.append('width', this.width);
            formData.append('height', this.height);
            formData.append('duration', this.duration);
            try {
                this.videoUploading = true;
                this.videoAsset = null;
                const response = await this.$apiRepository.uploadMediaAsset(formData);
                this.videoAsset = response?.data;
                this.$emit('update:asset', this.videoAsset);
                this.tempVideoUrl = null;
                const videoEl = this.$refs['video_'+this.aspectRatio];
                if (videoEl?.parentElement) {
                    videoEl.parentElement.load();
                }
            } catch (e) {
                this.setError('An error occurred while uploading video. Please contact support.');
                console.log(e);
                return;
            }
            this.videoUploading = false;
        }
    }
};
</script>

<style lang="scss" scoped>
.icons{
    display: block;
    text-align: right;
    position: relative;
    img{
        max-width: 15px;
        max-height: 15px;
        margin: 0 3px;
    }
}
.videos{
    display: flex;
}
input[type="file"]{
    display: none;
}
.error-message{
    color:red;
    font-size: 16px;
    margin-bottom: 5px;
}
.video{
    video{
        max-width: 100%;
        height: auto;
    }
}
.w-100{
    width: 100%;
}
.p-4{
    padding: 10px;
}
.images, .videos{
    margin-bottom: 30px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    label{
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top:0;
        bottom:0;
        left:0;
        right:0;
        cursor: pointer;
    }
    button{
        width:100%;
        height: 40px;
        position: relative;
    }
}
.flex-between{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.card-title{
    font-size: 18px;
}
</style>

<template>
    <v-form
        ref="form"
        v-model="valid"
        class="settings">
        <p class="section-title">
            Campaign
        </p>
        <v-radio-group
            v-model="campaignChoice"
            :mandatory="true"
            row>
            <v-radio
                label="Create New"
                value="new"
                color="primary" />
            <v-radio
                label="Use Existing"
                value="existing"
                color="primary" />
        </v-radio-group>
        <div v-if="campaignChoice === 'new'">
            <div class="field mt-2">
                <v-text-field
                    v-model="campaignName"
                    required
                    :rules="requiredRules"
                    label="Campaign Name"
                    class="styled-field mb-3"
                    @input="clearErrors" />
            </div>
            <div
                v-if="platform === 'facebook'"
                class="field">
                <v-select
                    v-model="specialAd"
                    return-object
                    required
                    :items="specialAds"
                    :rules="requiredRules"
                    item-text="text"
                    item-value="value"
                    label="Special Ad Categories"
                    class="styled-field mb-3" />
            </div>
            <div class="field">
                <v-select
                    v-model="objective"
                    return-object
                    required
                    :rules="requiredRules"
                    :items="objectives"
                    item-text="ui_name"
                    item-value="platform_value"
                    label="Objective"
                    class="styled-field mb-3"
                    @change="clearErrors" />
            </div>
            <budget-selection
                :platform="platform"
                :budget-choice="budgetChoice"
                :external-rules="budgetRules[budgetChoice]"
                @change-choice="setBudgetType"
                @set-budget="setBudget"
                @clear-errors="clearErrors" />
            <div
                v-if="platform === 'snapchat'"
                class="cap mt-3 mb-2">
                <v-text-field
                    v-model="dailyCap"
                    pattern="[0-9]"
                    prefix="$"
                    :rules="minAmountRules.daily"
                    label="Daily Spend Cap"
                    class="styled-field daily-cap"
                    @input="setMinimumCap('daily', $event)" />
                <v-text-field
                    v-model="lifetimeCap"
                    pattern="[0-9]"
                    required
                    prefix="$"
                    :rules="minAmountRules.lifetime"
                    label="Lifetime Spend Cap"
                    class="styled-field"
                    @input="setMinimumCap('lifetime', $event)" />
            </div>
            <div class="datepicker">
                <div
                    class="field"
                    :class="{emptyStart: startDate === null}">
                    <v-menu
                        ref="startDateMenu"
                        v-model="startDateMenu"
                        lazy
                        :close-on-content-click="false"
                        transition="scale-transition"
                        :return-value.sync="startDate"
                        class="custom-select">
                        <v-autocomplete
                            slot="activator"
                            label="Start Date"
                            :placeholder="startDate"
                            light
                            color="grey lighten-2"
                            readonly />
                        <v-date-picker
                            v-model="startDate"
                            :min="today"
                            :light="true"
                            @input="$refs.startDateMenu.save(startDate)" />
                    </v-menu>
                </div>
                <div
                    class="field"
                    :class="{emptyEnd: endDate === null}">
                    <v-menu
                        ref="endDateMenu"
                        v-model="endDateMenu"
                        lazy
                        :close-on-content-click="false"
                        transition="scale-transition"
                        :return-value.sync="endDate"
                        class="custom-select">
                        <v-autocomplete
                            slot="activator"
                            label="End Date"
                            :placeholder="endDate"
                            light
                            color="grey lighten-2"
                            readonly />
                        <v-date-picker
                            v-model="endDate"
                            :min="tomorrow"
                            :light="true"
                            @input="$refs.endDateMenu.save(endDate)" />
                    </v-menu>
                </div>
            </div>
            <div
                v-if="showEndDateErrorMessage"
                class="small-error-message">
                End date is required
            </div>
        </div>
        <div v-else>
            <div v-if="platform === 'tiktok'">
                Your TikTok Campaign will be paused once this deployment successfully completes.
            </div>
            <div class="field">
                <v-select
                    v-model="campaignExisting"
                    required
                    :rules="requiredRules"
                    :items="campaigns"
                    label="Campaign Name"
                    :loading="campaignFetchLoadingStatus"
                    :error="campaignFetchErrorStatus"
                    :error-messages="campaignFetchErrorMessage"
                    class="styled-field mb-3"
                    @change="clearErrors" />
            </div>
        </div>
    </v-form>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { requiredRules } from '@/helpers/validationRules';
import BudgetSelection from '@/components/creative-library/BudgetSelection';
import moment from 'moment';

export default {
    name: 'CampaignConfiguration',
    components: {
        BudgetSelection
    },
    props: {
        platform: {
            type: String,
            required: true
        },
    },
    data() {
        return {
            valid: false,
            campaignChoice: 'new',
            campaignName: '',
            specialAd: { text: 'None', value: 'NONE' },
            specialAds: [
                { text: 'Housing', value: 'HOUSING' },
                { text: 'Credit', value: 'CREDIT' },
                { text: 'Employment', value: 'EMPLOYMENT' },
                { text: 'Issues Elections Politics', value: 'ISSUES_ELECTIONS_POLITICS' },
                { text: 'None', value: 'NONE' }
            ],
            objective: [],
            objectives: [],
            budgetChoice: 'Daily',
            budget: 0,
            startDate: moment().add(1,'days').format('YYYY-MM-DD'),
            startDateMenu: false,
            endDate: null,
            tomorrow: moment().add(1,'days').format('YYYY-MM-DD'),
            today: moment().format('YYYY-MM-DD'),
            endDateMenu: null,
            campaignExisting: null,
            campaigns: [],
            campaignFetchLoadingStatus: false,
            campaignFetchErrorStatus: false,
            campaignFetchErrorMessage: '',
            showEndDateErrorMessage: false,
            endDateErrorMessage: false,
            existingCampaignId: null,
            dailyCap: null,
            lifetimeCap: null,
            budgetRules: {
                Daily: [],
                Lifetime: []
            },
            minAmountRules: {
                daily: [],
                lifetime: []
            },
            requiredRules
        };
    },
    computed: {
        ...mapState({
            currentDealer: (state) => state.dealer.currentDealer,
        }),
        ...mapGetters([
           'dealerPlatforms'
        ])
    },
    watch: {
        budget() {
            //validation for money format
            const currentBudget = this.budget.replace('$', '').replace('.00', '').replaceAll(',', '');
            const formatedBudget = this.$options.filters.formatCurrency(Number(currentBudget));
            this.budget = formatedBudget.replace('$', '').replace('.00', '');
        },
        startDate() {
            if (this.startDate > this.endDate) {
                //this.endDate = moment(this.startDate).add(1,'days').format('YYYY-MM-DD');
                this.endDate = null;
            }
            this.tomorrow = moment(this.startDate).add(1,'days').format('YYYY-MM-DD');
        },
        campaignChoice(val) {
            if (val === 'new') {
                this.$emit('block-existing', true, this.platform);
            } else {
                this.fetchCampaigns();
                this.$emit('block-existing', false, this.platform);
            }
        },
        campaignExisting() {
            this.$emit('existing-campaign-selection-updated', {
                campaignId: this.campaignExisting,
                platform: this.platform
            });
        },
        objective() {
            this.$emit('objective-change', this.objective, this.platform);
        }
    },
    mounted() {
        this.populateObjective();
        this.setRules();
    },
    methods: {
        setRules() {
            // add end date required if lifetime budget for tiktok

            if (this.platform === 'facebook') {
                this.budgetRules.Daily = [
                    v => ( v && v >= 2 ) || 'Daily budget should not be less then $2',
                ];

                this.budgetRules.Lifetime = [
                    v => ( v && v / this.computeDatesDiff(this.startDate, this.endDate) >= 2 ) || 'Facebook requires at least $2 a day for lifetime budgets'
                ];
            }

            if (this.platform === 'pinterest') {
                this.budgetRules.Daily = [
                    v => ( v && v >= 20 ) || 'Daily budget should not be less then $20',
                ];

                this.budgetRules.Lifetime = [
                    v => ( v && v / this.computeDatesDiff(this.startDate, this.endDate) >= 20 ) || 'Pinterest requires at least $20 a day for lifetime budgets'
                ];
            }

            if (this.platform === 'tiktok') {
                this.budgetRules.Daily = [
                    v => ( v && v >= 20 ) || 'Daily budget should not be less then $20',
                ];

                this.budgetRules.Lifetime = [
                    v => ( v && v / this.computeDatesDiff(this.startDate, this.endDate) >= 20 ) || 'TikTok requires at least $20 a day for lifetime budgets'
                ];
            }

            if (this.platform === 'snapchat') {
                this.budgetRules.Daily = [
                    v => ( v && v >= 20 ) || 'Daily budget should not be less then $20',
                ];

                this.budgetRules.Lifetime = [
                    v => ( v && v >= 350 ) || 'Lifetime budget should not be less then $350',
                ];
            }
        },
        computeDatesDiff(startDate, endDate) {
            const start = moment(startDate);
            const end = moment(endDate);
            const diff = end.diff(start, 'days');
            return diff;
        },
        setMinimumCap(inputType, value) {
            if (value == '') {
                this.minAmountRules[inputType] = [];
            } else {
                this.minAmountRules[inputType] = [
                    v => ( v && v >= 20 ) || 'Cap should not be less then $20',
                ];
            }
        },
        setBudget(budget) {
            this.budget = budget;
        },
        setBudgetType(budgetType) {
            this.budgetChoice = budgetType;
        },
        populateObjective() {
            const platformObjectives = {
                'facebook': [
                    {
                        ui_name: 'Video Views',
                        platform_value: 'VIDEO_VIEWS'
                    },
                    {
                        ui_name: 'Brand Awareness',
                        platform_value: 'BRAND_AWARENESS'
                    },
                    {
                        ui_name: 'Reach',
                        platform_value: 'REACH'
                    },
                    {
                        ui_name: 'Traffic',
                        platform_value: 'LINK_CLICKS'
                    },
                    {
                        ui_name: 'Engagement',
                        platform_value: 'POST_ENGAGEMENT'
                    }
                ],
                'snapchat': [
                    {
                        ui_name: 'Video Views',
                        platform_value: 'VIDEO_VIEW'
                    },
                    {
                        ui_name: 'Awareness',
                        platform_value: 'BRAND_AWARENESS'
                    },
                    {
                        ui_name: 'Drive Traffic to Website',
                        platform_value: 'WEB_VIEW'
                    }
                ],
                'tiktok': [
                    {
                        ui_name: 'Video Views',
                        platform_value: 'VIDEO_VIEWS'
                    },
                    {
                        ui_name: 'Reach',
                        platform_value: 'REACH'
                    },
                    {
                        ui_name: 'Drive Traffic to Website',
                        platform_value: 'TRAFFIC'
                    },
                ],
                'pinterest': [
                    {
                        ui_name: 'Video Views',
                        platform_value: 'VIDEO_VIEWS'
                    },
                    {
                        ui_name: 'Reach',
                        platform_value: 'REACH'
                    },
                    {
                        ui_name: 'Traffic',
                        platform_value: 'TRAFFIC'
                    },
                ]
            };

            if (this.platform) {
                this.objectives = platformObjectives[this.platform];
                this.objective = platformObjectives[this.platform][1];
            }
        },
        async fetchCampaigns() {
            this.campaigns = [];
            this.campaignFetchLoadingStatus = true;
            this.campaignFetchErrorStatus = false;
            this.campaignFetchErrorMessage = '';

            try {
                let adAccountId = this.getChannelAdAccountId();

                if(adAccountId) {
                    await this.$apiRepository
                    .getDealerPlatformAdAccountCampaigns({
                        dealerId: this.currentDealer.id,
                        platform: this.platform,
                        adAccountId
                    })
                    .then(response => {
                        if(response.data) {
                            response.data.forEach(campaign => {
                                this.campaigns.push({
                                    value: campaign.external_id,
                                    text: campaign.name
                                });
                            });
                        }
                    });
                } else {
                    this.campaignFetchErrorStatus = true;
                    this.campaignFetchErrorMessage = 'Ad Account not found';
                }
            } catch(error) {
                this.campaignFetchErrorStatus = true;
                this.campaignFetchErrorMessage = `Error occured while fetching campaigns for ${this.platform}`;

                console.log(error);
            } finally {
                this.campaignFetchLoadingStatus = false;
            }
        },
        getChannelAdAccountId() {
            let channel = this.dealerPlatforms.find(dp => dp.name === this.platform);

            let adAccountId = '';

            if(this.platform === 'facebook') {
                adAccountId = this.currentDealer.facebook_ad_account_annotations.data[0].facebook_ad_account_id.replace('act_', '');
            } else {
                let adAccount = this.currentDealer.ad_accounts.data.find(cd => cd.platform_id === channel.id);

                if(adAccount) {
                    adAccountId = adAccount.external_ad_account_id;
                }
            }

            return adAccountId;
        },
        submit() {
            this.$refs.form.validate();

            this.showEndDateErrorMessage = false;
            if(this.budgetChoice === 'Lifetime' && this.endDate === null) {
                this.showEndDateErrorMessage = true;
                return;
            }

            if (!this.valid) {
                return;
            }

            let formValues;
            if (this.campaignChoice === 'new') {
                formValues = {
                    channel: this.platform,
                    name: this.campaignName,
                    objective: this.objective?.platform_value,
                    special_ads_category: this.specialAd?.value,
                    budget: {
                        daily_budget: this.budgetChoice === 'Daily' ? this.budget : null,
                        daily_spend_cap: this.dailyCap,
                        lifetime_budget: this.budgetChoice === 'Lifetime' ? this.budget : null,
                        lifetime_spend_cap: this.lifetimeCap,
                        currency: 'USD',
                        period: this.budgetChoice,
                        start_date: this.startDate,
                        end_date: this.endDate
                    }
                };
            } else {
                formValues = {
                    channel: this.platform,
                    existing_campaign_id: this.campaignExisting,
                };
            }
            this.$emit('provide-data', formValues, this.platform);
        },
        clearErrors() {
            this.$emit('clear-errors');
        },
    }
};
</script>

<style lang="scss" scoped>
.settings{
    padding: 0 30px 30px 30px;
}
.section-title {
    font-size: 16px;
    background: #f3f5f6;
    font-weight: 600;
    margin-left: -30px;
    margin-right: -30px;
    padding: 5px 0;
    padding-left: 30px;
}
.field{
    margin-top: 30px;
}
.emptyEnd{
    position: relative;
    &:after{
        content: 'Indefinitely';
        position: absolute;
        bottom: 18px;
        left:15px;
        font-size: 16px;
        pointer-events: none;
    }
}
.emptyStart{
    position: relative;
    &:after{
        content: 'Today';
        position: absolute;
        bottom: 18px;
        left:15px;
        font-size: 16px;
        pointer-events: none;
    }
}
.cap{
    display:inline-flex;
    justify-content: flex-start;
}
.cap > div{
    display: inline-block;
    max-width: 185px;
}
.daily-cap {
    margin-right: 30px;
}
.type-title{
    display: block;
    margin-top: 30px;
}
.datepicker{
    display: flex;
    .field {
        margin-right: 30px;
        max-width: 185px;
    }
}
.error-message{
    color:red;
    font-size: 16px;
    margin-bottom: 5px;
}
.small-error-message{
    color:rgb(244, 67, 54);
    font-size: 12px;
    margin-bottom: 5px;
    margin-left: 215px;
}
</style>
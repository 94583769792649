<template>
    <v-form
        ref="form"
        v-model="valid"
        class="settings">
        <loader
            v-if="isLoading" />
        <div v-else>
            <!-- media -->
            <p class="section-title">
                Ad
            </p>
            <v-select
                v-model="type"
                :items="types"
                :disabled="true"
                label="Type"
                class="styled-field mb-3" />
            <div class="images-error">
                {{ mediaError }}
            </div>
            <div
                v-if="type === 'Video'"
                class="videos">
                <upload-video-asset
                    :icons="['facebook', 'snapchat', 'tiktok']"
                    aspect-ratio="9:16"
                    :asset.sync="nineBySixteenVideoAsset" />
                <upload-video-asset
                    :icons="['pinterest']"
                    aspect-ratio="2:3"
                    :asset.sync="twoByThreeVideoAsset" />
                <upload-video-asset
                    :icons="['facebook']"
                    aspect-ratio="4:5"
                    :asset.sync="fourByFiveVideoAsset" />
            </div>

            <div
                v-if="type === 'Image'"
                class="images">
                <upload-image-asset
                    :icons="['facebook', 'snapchat']"
                    aspect-ratio="9:16"
                    :asset.sync="nineBySixteenImageAsset" />
                <upload-image-asset
                    :icons="['pinterest']"
                    aspect-ratio="2:3"
                    :asset.sync="twoByThreeImageAsset" />
                <upload-image-asset
                    :icons="['facebook']"
                    aspect-ratio="1:1"
                    :asset.sync="oneByOneImageAsset" />
            </div>
            <!-- end of media -->

            <!-- content -->
            <p class="section-title">
                Content
            </p>
            <div class="input-holder">
                <platform-icons :platforms="activeDeploymentChannels" />
                <v-text-field
                    v-if="onReview"
                    v-model="name"
                    required
                    :rules="[...requiredRules]"
                    label="Ad Name"
                    class="styled-field mb-3"
                    @input="clearErrors" />
            </div>
            <div class="input-holder">
                <platform-icons :platforms="activeDeploymentChannels" />
                <v-text-field
                    v-model="headline"
                    required
                    :rules="[
                        ...requiredRules,
                        ...(activeDeploymentChannels.includes('snapchat') ? [...snapchatHeadline] : [])
                    ]"
                    label="Headline"
                    class="styled-field mb-3"
                    @input="clearErrors" />
            </div>
            <div class="input-holder">
                <platform-icons :platforms="activeDeploymentChannels" />
                <v-textarea
                    v-model="description"
                    required
                    :rules="requiredRules"
                    label="Description"
                    class="styled-field mb-3"
                    @input="clearErrors" />
            </div>
            <div class="input-holder">
                <platform-icons :platforms="activeDeploymentChannels" />
                <v-select
                    v-model="cta_text"
                    required
                    :rules="requiredRules"
                    :items="actions"
                    label="Call to action text"
                    class="styled-field mb-3"
                    @change="clearErrors" />
            </div>
            <div class="input-holder">
                <platform-icons :platforms="activeDeploymentChannels" />
                <v-text-field
                    v-if="onReview"
                    v-model="cta_link"
                    required
                    :rules="[...requiredRules, ...urlRules]"
                    label="Landing Page"
                    class="styled-field mb-3"
                    @input="clearErrors" />
            </div>

            <p
                v-if="activeDeploymentChannels.includes('snapchat') || activeDeploymentChannels.includes('tiktok')"
                class="section-title">
                Platform Specific Settings
            </p>
            <div
                v-if="activeDeploymentChannels.includes('snapchat')"
                class="input-holder">
                <platform-icons :platforms="['snapchat']" />
                <div class="layout align-center">
                    <div class="flex">
                        <v-select
                            v-model="selectedSnapchatCallToAction"
                            return-object
                            :items="snapchatCallToActions"
                            :rules="requiredRules"
                            placeholder="Select Call to Action"
                            item-text="text"
                            label="Snapchat Call to Action"
                            class="styled-field mb-3" />
                    </div>
                </div>
            </div>

            <div
                v-if="activeDeploymentChannels.includes('tiktok')"
                class="input-holder">
                <platform-icons :platforms="['tiktok']" />
                <div class="layout align-center">
                    <div class="flex">
                        <v-select
                            v-model="selectedTikTokIdentity"
                            return-object
                            :items="tikTokIdentities"
                            :rules="requiredRules"
                            :loading="tikTokIdentityLoading"
                            placeholder="Select identity"
                            item-text="display_name"
                            label="Identity"
                            class="styled-field mb-3" />
                    </div>
                    <div class="flex shrink mb-3">
                        <action-button
                            size="18"
                            icon="refresh"
                            @click="getTikTokIdentities" />
                    </div>
                </div>


                <styled-callout
                    v-if="(tikTokIdentities.length === 0) && !tikTokIdentityLoading"
                    type="error">
                    <div class="layout align-center">
                        <div class="flex shrink">
                            <status-icon
                                class="mr-3"
                                value="warning"
                                size="23" />
                        </div>
                        <div>
                            There are no TikTok Identities created for this account. Please go into TikTok Ads Manager and create one so it can be used during Ad Deployment as it is a required field.
                        </div>
                    </div>
                </styled-callout>
            </div>
            <!-- end of content -->
        </div>
    </v-form>
</template>

<script>
import { mapState } from 'vuex';
import Loader from '@/components/globals/Loader';
import StatusIcon from '@/components/globals/StatusIcon';
import StyledCallout from '@/components/globals/StyledCallout';
import ActionButton from '@/components/globals/ActionButton';
import UploadVideoAsset from '@/components/creative-library/UploadVideoAsset';
import UploadImageAsset from '@/components/creative-library/UploadImageAsset';
import PlatformIcons from '@/components/creative-library/PlatformIcons.vue';
import {
    requiredRules,
    snapchatHeadline,
    urlRules
} from '@/helpers/validationRules';

export default {
    name: 'EditCreativeForm',
    components: {
        StyledCallout,
        StatusIcon,
        ActionButton,
        Loader,
        UploadVideoAsset,
        UploadImageAsset,
        PlatformIcons
    },
    props: {
        creativeAd: {
            type: Object,
            required: false,
            default: () => ({})
        },
        activeChannels: {
            type: Array,
            required: false,
            default: () => []
        },
        reset: {
            type: Boolean,
            required: false,
        },
        onReview: {
            type: Boolean,
            required: false,
            default: false
        },
    },
    data() {
        return {
            valid: false,
            type: 'Image',
            types: ['Image', 'Video'],
            fourByFiveVideoAsset: null,
            nineBySixteenVideoAsset: null,
            twoByThreeVideoAsset: null,
            oneByOneImageAsset: null,
            nineBySixteenImageAsset: null,
            twoByThreeImageAsset: null,
            selectedSnapchatCallToAction: null,
            snapchatCallToActions: [
                { text: 'Apply Now', value: 'APPLY_NOW' },
                { text: 'Get Now', value: 'GET_NOW' },
                { text: 'Order Now', value: 'ORDER_NOW' },
                { text: 'Sign Up', value: 'SIGN_UP' },
                { text: 'View', value: 'VIEW' },
                { text: 'More', value: 'MORE' },
                { text: 'Shop Now', value: 'SHOP_NOW' }
            ],
            mediaError: '',
            assetIds: [],
            name: '',
            description: '',
            headline: '',
            cta_text: 'Learn More',
            actions: ['Learn More', 'Shop Now'],
            cta_link: '',
            title: '',
            selectedPlaybooks: [],
            playbooks: [],
            initialPlaybooks: [],
            syncedPlaybooks: [],
            platforms: ['Facebook', 'Pinterest', 'Snapchat', 'Tiktok'],
            activeDeploymentChannels: [],
            // selectedPlatforms: [],
            errorMessage: '',
            channels: new Set(),
            isLoading: false,
            requiredRules,
            snapchatHeadline,
            createdAdId: null,
            urlRules,
            tikTokIdentityLoading: false,
            selectedTikTokIdentity: null,
            tikTokIdentities: []
        };
    },
    watch: {
        type() {
            this.mediaError = '';
            this.errorMessage = '';
        },
    },
    created() {
        this.activeDeploymentChannels = Array.from(this.activeChannels);
    },
    computed: {
        ...mapState({
            currentDealer: (state) => state.dealer.currentDealer,
            currentAgency: (state) => state.agency.currentAgency,
        }),
    },
    async mounted() {
        this.prePopulateFields();

        if (this.activeDeploymentChannels.includes('tiktok')) {
            await this.getTikTokIdentities();
        }

        if (this.activeDeploymentChannels.includes('snapchat')) {
            await this.initSnapchatCallToAction();
        }
    },
    methods: {
        initSnapchatCallToAction() {
            this.snapchatCallToActions.forEach(action => {
                if (this.creativeAd?.full_configuration?.creatives[0]?.call_to_action_text === action.text) {
                    this.selectedSnapchatCallToAction = action;
                }
            });
        },
        validateFields() {
            this.errorMessage = '';
            this.$refs.form.validate();
            if (!this.valid) {
                this.errorMessage = 'Please provide all required data';
                return;
            }

            if (this.type === 'Image') {
                if (!(this.oneByOneImageAsset || this.nineBySixteenImageAsset || this.twoByThreeImageAsset)) {
                    this.errorMessage = this.mediaError = 'Please upload a photo';
                    return;
                }
            }

            if (this.type === 'Video') {
                if (!(this.fourByFiveVideoAsset || this.nineBySixteenVideoAsset || this.twoByThreeVideoAsset)) {
                    this.errorMessage = this.mediaError = 'Please upload a video';
                    return;
                }
            }
        },
        async submit() {
            this.validateFields();
            if (!this.valid) {
                return;
            }
            this.deployAds();
        },
        deployAds() {
            // Make sure that we have correct ids if we change type during editing
            if (this.type == 'Image') {
                this.assets = [this.oneByOneImageAsset, this.nineBySixteenImageAsset, this.twoByThreeImageAsset].filter(x => x);
            }
            if (this.type == 'Video') {
                this.assets = [this.fourByFiveVideoAsset, this.nineBySixteenVideoAsset, this.twoByThreeVideoAsset].filter(x => x);
            }

            const data = {
                name: this.name,
                render_type: this.type,
                // platform_id: this.selectedPlatforms,
                headline: this.headline,
                full_configuration: {
                    headline: this.headline,
                    description: this.description,
                    render_type: this.type,
                    creatives: [
                        {
                            call_to_action_link : this.cta_link,
                            call_to_action_text : this.cta_text,
                            platform_specific_fields: {
                                snapchat_call_to_action: this.selectedSnapchatCallToAction?.value,
                            },
                            assets: this.assets
                        }
                    ]
                },
                platform_specific_fields: {
                    identity_id: this.selectedTikTokIdentity?.id,
                    identity_type: this.selectedTikTokIdentity?.identity_type
                }
            };

            this.$emit('deploy-ads', data);
        },
        clearErrors() {
            this.errorMessage = '';
            this.$emit('clear-errors');
        },
        addChannel(event) {
            if (event.target.checked) {
                this.channels.add(event.target.value);
            } else {
                this.channels.delete(event.target.value);
            }
        },
        prePopulateFields() {
            if (!this.creativeAd) {
                return;
            }

            //clear errors
            this.errorMessage = '';
            this.mediaError = '';

            //set fields
            this.type = this.creativeAd?.full_configuration?.creatives[0]?.assets[0]?.asset_type.display_name;

            // Images
            this.oneByOneImageAsset = this.creativeAd?.full_configuration?.creatives[0]?.assets.filter(asset => asset.aspect_ratio === '1:1' && asset.asset_type_id == 1)[0];
            this.nineBySixteenImageAsset = this.creativeAd?.full_configuration?.creatives[0]?.assets.filter(asset => asset.aspect_ratio === '9:16' && asset.asset_type_id == 1)[0];
            this.twoByThreeImageAsset = this.creativeAd?.full_configuration?.creatives[0]?.assets.filter(asset => asset.aspect_ratio === '2:3' && asset.asset_type_id == 1)[0];

            // Videos
            this.fourByFiveVideoAsset = this.creativeAd?.full_configuration?.creatives[0]?.assets.filter(asset => asset.aspect_ratio === '4:5' && asset.asset_type_id == 2)[0];
            this.nineBySixteenVideoAsset = this.creativeAd?.full_configuration?.creatives[0]?.assets.filter(asset => asset.aspect_ratio === '9:16' && asset.asset_type_id == 2)[0];
            this.twoByThreeVideoAsset = this.creativeAd?.full_configuration?.creatives[0]?.assets.filter(asset => asset.aspect_ratio === '2:3' && asset.asset_type_id == 2)[0];

            this.name = this.creativeAd?.full_configuration?.name;
            this.description = this.creativeAd?.full_configuration?.description;
            this.headline = this.creativeAd?.full_configuration?.headline;
            this.cta_text = this.creativeAd?.full_configuration?.creatives[0]?.call_to_action_text;
            this.cta_link = this.creativeAd?.full_configuration?.creatives[0]?.call_to_action_link;
            this.title = this.creativeAd?.display_name;
            // this.selectedPlatforms = this.creativeAd?.configuration?.platforms;
            this.assetIds = this.creativeAd?.full_configuration?.creatives[0]?.asset_ids;
            //this.playbook = this.creativeAd?.playbook; // todo map the playbooks to the ad

            this.$nextTick(function() {
                this.$refs.checkboxes?.children.forEach(checkboxComponent => {
                    if (this.creativeAd.platforms?.includes(checkboxComponent.childNodes[1].value)) {
                        checkboxComponent.childNodes[1].checked = true;
                        this.channels.add(checkboxComponent.childNodes[1].value);
                    } else {
                        checkboxComponent.childNodes[1].checked = false;
                        this.channels.delete(checkboxComponent.childNodes[1].value);
                    }
                });
            });
        },
        async getTikTokIdentities() {
            this.tikTokIdentityLoading = true;
            this.tikTokIdentities = [];
            try {
                const response = await this.$http.get(`/dealers/${this.currentDealer.id}/platforms/tiktok/identities`);
                response.data.forEach(tikTokIdentity => {
                    this.tikTokIdentities.push({
                        'display_name': tikTokIdentity?.display_name,
                        'id': tikTokIdentity?.identity_id,
                        'identity_type': tikTokIdentity?.identity_type
                    });
                });

                this.selectedTikTokIdentity = this.tikTokIdentities[0];
            } catch(error) {
                this.tikTokIdentityLoading = false;
                console.log(error);
            } finally{
                this.tikTokIdentityLoading = false;
            }
        }
    },
};
</script>

<style lang="scss" scoped>
.settings{
    padding: 0 30px 30px 30px;
}
.section-title {
    font-size: 16px;
    background: #f3f5f6;
    font-weight: 600;
    margin-left: -30px;
    margin-right: -30px;
    padding: 5px 0;
    padding-left: 30px;
}
.checkboxes{
    display: flex;
    margin-bottom: 30px;
    label{
        margin-right: 30px;
    }
}
.icons{
    display: block;
    text-align: right;
    position: relative;
    img{
        max-width: 15px;
        max-height: 15px;
        margin: 0 3px;
    }
}
.images, .video{
    margin-bottom: 30px;
    label{
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top:0;
        bottom:0;
        left:0;
        right:0;
        cursor: pointer;
    }
    button{
        width:150px;
        height: 40px;
        position: relative;
    }
}
.images{
    display: flex;
}
.videos{
    display: flex;
    justify-content: space-between;
}
input[type="file"]{
    display: none;
}
.portrait_preview{
    max-width: 225px;
    max-height: 400px;
}
.square_preview{
    max-width: 225px;
    max-height: 225px;
}
.images-error, .error-message{
    color:red;
    font-size: 16px;
    margin-bottom: 5px;
}
.mb-30{
    margin-bottom:30px;
}
.save{
    text-align: center;
    button{
        width: 180px;
        font-size: 18px;
    }
}
.temporary-note{
    text-align: center;
}
.video{
    video{
        max-width: 300px;
        height: auto;
    }
}
.delete{
    margin-top: 25px;
    text-align: center;
}
</style>

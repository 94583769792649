<template>
    <v-form
        ref="form"
        v-model="valid"
        class="settings">
        <p class="section-title">
            Ad Set
        </p>
        <v-radio-group
            v-model="adsetChoice"
            :mandatory="true"
            row>
            <v-radio
                label="Create New"
                value="new"
                :disabled="!cantUseExisting"
                color="primary" />
            <v-radio
                label="Use Existing"
                value="existing"
                :disabled="cantUseExisting"
                color="primary" />
        </v-radio-group>
        <div v-if="adsetChoice === 'new'">
            <div class="field mt-2">
                <v-text-field
                    v-model="adsetName"
                    required
                    :rules="requiredRules"
                    label="Adset Name"
                    class="styled-field mb-3"
                    @input="clearErrors" />
            </div>
            <!-- <budget-selection
                v-if="platform == 'snapchat'"
                :platform="platform"
                :budget-choice="budgetChoice"
                @change-choice="setBudgetType"
                @set-budget="setBudget"
                @clear-errors="clearErrors" /> -->
            <h3 class="type-title">
                Geo Locations
            </h3>
            <div v-if="platform === 'pinterest'">
                <div class="field">
                    <v-combobox
                        v-model="selectedZipCodes"
                        :items="zipCodes"
                        label="Zip Codes"
                        chips
                        multiple
                        required
                        :rules="requiredComboboxRules"
                        class="styled-field"
                        @keyup="clearErrors">
                        <template #selection="{ attrs, item, parent, selected }">
                            <v-chip
                                v-if="validZip(item)"
                                v-bind="attrs"
                                :selected="selected"
                                small>
                                <span class="pr-2">
                                    {{ item }}
                                </span>
                                <v-icon
                                    small
                                    @click="parent.selectItem(item)">
                                    close
                                </v-icon>
                            </v-chip>
                        </template>
                    </v-combobox>
                </div>
            </div>
            <div v-else>
                <div v-if="platform !== 'tiktok'">
                    <gmap-map
                        ref="map"
                        :center="coordinates"
                        :zoom="zoom"
                        :options="{
                            zoomControl: true,
                            mapTypeControl: false,
                            scaleControl: false,
                            streetViewControl: false,
                            rotateControl: false,
                            fullscreenControl: false,
                            disableDefaultUi: true
                        }"
                        :style="{ width: '100%', height: '100%', 'min-height': '250px' }">
                        <gmap-marker
                            ref="marker"
                            :icon="google && {
                                path: google.maps.SymbolPath.CIRCLE,
                                fillColor: colors.blue,
                                fillOpacity: 1,
                                strokeWeight: 0,
                                scale: 6
                            }"
                            :position="coordinates" />
                    </gmap-map>

                    <div class="radius-wrapper">
                        <v-layout
                            row
                            justify-center
                            align-center>
                            <div class="radius">
                                <v-text-field
                                    v-model="radiusValue"
                                    class="styled-field mt-3"
                                    type="number"
                                    label="Radius"
                                    suffix="miles"
                                    :rules="[...requiredRules, ...hecRadiusRules]"
                                    light />
                            </div>
                            <div class="coordinates">
                                {{ currentDealer.latitude }} , {{ currentDealer.longitude }}
                            </div>
                        </v-layout>
                    </div>
                </div>
                <div v-else>
                    <v-combobox
                        v-model="selectedDesignatedMarketingAreas"
                        :items="designatedMarketingAreas"
                        :return-object="true"
                        item-text="name"
                        item-value="id"
                        label="Designated Marketing Areas"
                        :loading="dmaFetchLoadingStatus"
                        :error="dmaFetchErrorStatus"
                        :error-messages="dmaFetchErrorMessage"
                        chips
                        multiple
                        required
                        class="styled-field mt-3"
                        @change="clearErrors">
                        <template #selection="{ attrs, item, parent, selected }">
                            <v-chip
                                v-if="item === Object(item)"
                                v-bind="attrs"
                                :selected="selected"
                                small>
                                <span class="pr-2">
                                    {{ item.name }}
                                </span>
                                <v-icon
                                    small
                                    @click="parent.selectItem(item)">
                                    close
                                </v-icon>
                            </v-chip>
                        </template>
                    </v-combobox>
                </div>
            </div>

            <div class="field">
                <div v-if="platform !== 'tiktok'">
                    <v-combobox
                        v-model="selectedAudiences"
                        :items="existingAudiences"
                        :return-object="true"
                        item-text="name"
                        item-value="external_id"
                        label="Custom Audience"
                        :loading="audienceFetchLoadingStatus"
                        :error="audienceFetchErrorStatus"
                        :error-messages="audienceFetchErrorMessage"
                        chips
                        multiple
                        class="styled-field"
                        @keyup="clearErrors">
                        <template #selection="{ attrs, item, parent, selected }">
                            <v-chip
                                v-if="item === Object(item)"
                                v-bind="attrs"
                                :selected="selected"
                                small>
                                <span class="pr-2">
                                    {{ item.name }}
                                </span>
                                <v-icon
                                    small
                                    @click="parent.selectItem(item)">
                                    close
                                </v-icon>
                            </v-chip>
                        </template>
                    </v-combobox>
                </div>
            </div>
            <div>
                <div class="field">
                    <v-select
                        v-model="goal"
                        return-object
                        required
                        :items="goals"
                        item-text="ui_name"
                        item-value="platform_value"
                        label="Optimization Goal"
                        class="styled-field mb-3" />
                </div>
            </div>
            <div>
                <div class="field">
                    <v-select
                        v-model="billingEvent"
                        return-object
                        required
                        :rules="requiredRules"
                        :items="billingEvents"
                        item-text="ui_name"
                        item-value="platform_value"
                        label="Billing Event"
                        class="styled-field mb-3" />
                </div>
            </div>
        </div>
        <div v-else>
            <div class="field">
                <v-select
                    v-model="existingAdSet"
                    required
                    :rules="requiredRules"
                    :items="adSets"
                    :loading="adSetFetchLoadingStatus"
                    :error="adSetFetchErrorStatus"
                    :error-messages="adSetFetchErrorMessage"
                    label="Adset Name"
                    class="styled-field mb-3"
                    @change="clearErrors" />
            </div>
        </div>
    </v-form>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import validationRules from '@/helpers/validationRules';
import GmapMap from 'vue2-google-maps/src/components/map';
import GmapMarker from 'vue2-google-maps/src/components/marker';
// import BudgetSelection from '@/components/creative-library/BudgetSelection';
import { gmapApi } from 'vue2-google-maps';
import colors from '@/helpers/colors';

export default {
    name: 'AdsetConfiguration',
    components: {
        GmapMap,
        GmapMarker,
        // BudgetSelection
    },
    props: {
        platform: {
            type: String,
            required: true
        },
        cantUseExisting:{
            type: Boolean,
            required: true
        },
        existingSelectedCampaign: {
            type: Object,
            default: () => ({})
        },
        objective: {
            type: [Object, Array],
            required: true,
            default: () => ({})
        }
    },
    data() {
        return {
            adsetChoice: 'new',
            adsetName: '',
            budgetChoice: null,
            budget: null,
            zipCodes: [],
            selectedZipCodes: [],
            designatedMarketingAreas: [],
            dmaFetchLoadingStatus: false,
            dmaFetchErrorStatus: false,
            dmaFetchErrorMessage: '',
            selectedDesignatedMarketingAreas: [],
            adSets: [],
            adSetFetchLoadingStatus: false,
            adSetFetchErrorStatus: false,
            adSetFetchErrorMessage: '',
            existingAdSet: null,
            selectedAudiences: [],
            existingAudiences: [],
            audienceFetchLoadingStatus: false,
            audienceFetchErrorStatus: false,
            audienceFetchErrorMessage: '',
            goal: {},
            goals: [],
            billingEvent: {},
            billingEvents: [],
            circle: null,
            valid: false,
            coordinates: null,
            zoom: 15,
            radiusValue: 15,
            requiredRules: validationRules.requiredRules,
            requiredComboboxRules: validationRules.requiredComboboxRules,
            hecRadiusRules: validationRules.hecRadiusRules,
            milesPerMeter: 0.00062137,
            colors
        };
    },
    computed: {
        ...mapState({
            currentDealer: (state) => state.dealer.currentDealer,
        }),
        ...mapGetters([
           'dealerPlatforms'
        ]),
        currentRadius() {
            return this.radiusValue / this.milesPerMeter;
        },
        google: gmapApi
    },
    watch: {
        currentRadius(value) {
            this.setRadius(value);
        },
        adsetChoice() {
            if (this.adsetChoice === 'new') {
                if(this.platform !== 'tiktok') {
                    this.$nextTick(function() {
                        this.initCircle();
                    });
                }
            }
        },
        cantUseExisting(value) {
            if (value === true) {
                this.adsetChoice = 'new';
            } else {
                this.adsetChoice = 'existing';
            }
        },
        existingSelectedCampaign(value) {
            if(this.platform === value.platform) {
                this.fetchAdSets();
            }
        },
        goal() {
            this.setBillingEvents();
        },
        objective(objectiveObject) {
            this.setGoals(objectiveObject);
        }
    },
    created() {
        this.coordinates = {
            lat: parseFloat(this.currentDealer.latitude),
            lng: parseFloat(this.currentDealer.longitude)
        };

        this.fetchDesignatedMarketingAreas();
        this.fetchCustomAudiences();
    },
    mounted() {
        if (this.platform !== 'pinterest' && this.platform !== 'tiktok') {
            this.initCircle();
        }
    },
    methods: {
        setBudget(budget) {
            this.budget = budget;
        },
        setBudgetType(budgetType) {
            this.budgetChoice = budgetType;
        },
        async initCircle() {
            await this.$refs.marker.$markerPromise;
            // eslint-disable-next-line
            this.circle = new google.maps.Circle({
                map: this.$refs.map.$mapObject,
                radius: this.currentRadius,
                fillColor: colors.blue,
                strokeColor: colors.blue,
                strokeOpacity: 0.5,
                strokeWeight: 1,
            });
            this.circle.bindTo('center', this.$refs.marker.$markerObject, 'position');
            this.setRadius(this.currentRadius);
        },
        async fetchAdSets() {
            this.adSets = [];
            this.adSetFetchLoadingStatus = true;
            this.adSetFetchErrorStatus = false;
            this.adSetFetchErrorMessage = '';

            try {
                const config = {
                    dealerId: this.currentDealer.id,
                    platform: this.platform,
                    params: {
                        page_size: 100,
                        stats: false,
                        campaignIds: [ this.existingSelectedCampaign.campaignId ],
                    }
                };

                const response = await this.$apiRepository.getInternalDealerPlatformAdsSetPreview(config);

                response.data.data.forEach(adSet => {
                    this.adSets.push({
                        value: adSet.external_id,
                        text: adSet.name
                    });
                });
            } catch(error) {
                this.adSetFetchErrorStatus = true;
                this.adSetFetchErrorMessage = `Error occured while fetching ad set for ${this.platform}`;

                console.log(error);
            } finally {
                this.adSetFetchLoadingStatus = false;
            }
        },
        async fetchDesignatedMarketingAreas() {
            this.dmaFetchLoadingStatus = true;
            this.dmaFetchErrorStatus = false;
            this.dmaFetchErrorMessage = '';

            try {
                await this.$apiRepository
                .getDesignatedMarketingAreas()
                .then(response => {
                    if(response.data) {
                        this.designatedMarketingAreas = response.data.data;
                    }
                });
            } catch(error) {
                this.dmaFetchErrorStatus = true;
                this.dmaFetchErrorMessage = `Error occured while fetching DMAs for ${this.platform}`;

                console.log(error);
            } finally {
                this.dmaFetchLoadingStatus = false;
            }
        },
        async fetchCustomAudiences() {
            this.audienceFetchLoadingStatus = true;
            this.audienceFetchErrorStatus = false;
            this.audienceFetchErrorMessage = '';

            try {
                let adAccountId = this.getChannelAdAccountId();

                if(adAccountId) {
                    await this.$apiRepository
                    .getDealerPlatformCustomAudiences({
                        dealerId: this.currentDealer.id,
                        platform: this.platform,
                        adAccountId
                    })
                    .then(response => {
                        if(response.data) {
                            this.existingAudiences = response.data;
                        }
                    });
                } else {
                    this.audienceFetchErrorStatus = true;
                    this.audienceFetchErrorMessage = 'Ad Account not found';
                }
            } catch(error) {
                this.audienceFetchErrorStatus = true;
                this.audienceFetchErrorMessage = `Error occurred while fetching audience for ${this.platform}`;

                console.log(error);
            } finally {
                this.audienceFetchLoadingStatus = false;
            }
        },
        getChannelAdAccountId() {
            let channel = this.dealerPlatforms.find(dp => dp.name === this.platform);

            let adAccountId = '';

            if(this.platform === 'facebook') {
                adAccountId = this.currentDealer.facebook_ad_account_annotations.data[0].facebook_ad_account_id.replace('act_', '');
            } else {
                let adAccount = this.currentDealer.ad_accounts.data.find(cd => cd.platform_id === channel.id);

                if(adAccount) {
                    adAccountId = adAccount.external_ad_account_id;
                }
            }

            return adAccountId;
        },
        setRadius(radius) {
            if (!radius || !this.circle) {
                return;
            }
            this.circle.setRadius(radius);

            // eslint-disable-next-line
            const bounds = new google.maps.LatLngBounds();
            bounds.union(this.circle.getBounds());
            this.$refs.map.$mapObject.fitBounds(bounds);
        },
        validAudience(i) {
            if (this.existingAudiences.includes(i)) {
                return true;
            } else {
                this.selectedAudiences = this.selectedAudiences.filter(item => item !== i);
                return false;
            }
        },
        validZip(i) {
            if (i.length === 5) {
                return true;
            } else {
                this.selectedZipCodes = this.selectedZipCodes.filter(item => item !== i);
                return false;
            }
        },
        submit() {
            this.$refs.form.validate();
            if (!this.valid) {
                return;
            }

            let formValues;
            if (this.adsetChoice === 'new') {
                formValues = {
                    channel: this.platform,
                    name: this.adsetName,
                    location_type: 'LOCATION_TYPE_NONE',
                    optimization_goal: this.goal?.platform_value,
                    billing_event: this.billingEvent?.platform_value,
                    budget: { // Budget is only being set at the campaign level at the moment
                        daily_budget: null, //this.budgetChoice === 'Daily' ? this.budget : null,
                        daily_spend_cap: null,
                        lifetime_budget: null, // this.budgetChoice === 'Lifetime' ? this.budget : null,
                        lifetime_spend_cap: null,
                        currency: null, // 'USD',
                        period: null, // this.budgetChoice,
                        start_date: null,
                        end_date: null
                    },
                    targeting: {
                        custom_audience_ids: this.selectedAudiences.map(a => a.external_id),
                        designated_marketing_area_ids: this.selectedDesignatedMarketingAreas.map(a => a.id).filter(a => a),
                        geo_locations: [
                            {
                                latitude: this.currentDealer.latitude,
                                longitude: this.currentDealer.longitude,
                                radius: this.radiusValue,
                                unit: 'MILES'
                            }
                        ],
                        age_min: 18,
                        age_max: null,
                        platform_specific_fields: {
                            tiktok_ages: [
                                'AGE_18_24',
                                'AGE_25_34',
                                'AGE_35_44',
                                'AGE_45_54',
                                'AGE_55_100'
                            ]
                        },
                    },
                };
            } else {
                formValues = {
                    channel: this.platform,
                    existing_ad_set_id: this.existingAdSet,
                };
            }
            this.$emit('provide-data', formValues, this.platform);
        },
        clearErrors() {
            this.$emit('clear-errors');
        },
        setGoals(objective) {
            this.goals = [];
            this.goal = [];

            const goalMappings = {
                'snapchat': {
                    'BRAND_AWARENESS': [
                        {
                            ui_name: 'Impressions',
                            platform_value: 'IMPRESSIONS'
                        },
                        {
                            ui_name: 'Swipes',
                            platform_value: 'SWIPES'
                        }
                    ],
                    'VIDEO_VIEW': [
                        {
                            ui_name: 'Video Views 15 Seconds',
                            platform_value: 'VIDEO_VIEWS_15_SEC'
                        },
                        {
                            ui_name: 'Video Views',
                            platform_value: 'VIDEO_VIEWS'
                        }
                    ],
                    'WEB_VIEW': [
                        {
                            ui_name: 'Pixel Page View',
                            platform_value: 'PIXEL_PAGE_VIEW'
                        },
                        {
                            ui_name: 'Swipes',
                            platform_value: 'SWIPES'
                        }
                    ]
                },
                'tiktok': {
                    'REACH': [
                        {
                            ui_name: 'Reach',
                            platform_value: 'REACH'
                        }
                    ],
                    'TRAFFIC': [
                        {
                            ui_name: 'Click',
                            platform_value: 'CLICK'
                        },
                    ],
                    'VIDEO_VIEWS': [
                        {
                            ui_name: 'Video View',
                            platform_value: 'VIDEO_VIEW'
                        },
                    ]
                },
                'facebook': {
                    'BRAND_AWARENESS': [
                        {
                            ui_name: 'Brand Awareness',
                            platform_value: 'AD_RECALL_LIFT'
                        }
                    ],
                    'REACH': [
                        {
                            ui_name: 'Reach',
                            platform_value: 'REACH'
                        },
                    ],
                    'LINK_CLICKS': [
                        {
                            ui_name: 'Link Clicks',
                            platform_value: 'LINK_CLICKS'
                        },
                        {
                            ui_name: 'Impressions',
                            platform_value: 'IMPRESSIONS'
                        },
                        {
                            ui_name: 'Landing Page Views',
                            platform_value: 'LANDING_PAGE_VIEWS'
                        },
                    ],
                    'POST_ENGAGEMENT': [
                        {
                            ui_name: 'Post Engagement',
                            platform_value: 'POST_ENGAGEMENT'
                        },
                        {
                            ui_name: 'Impressions',
                            platform_value: 'IMPRESSIONS'
                        },
                    ],
                    'VIDEO_VIEWS': [
                        {
                            ui_name: 'Video Views',
                            platform_value: 'VIDEO_VIEWS'
                        },
                        {
                            ui_name: 'Thruplay',
                            platform_value: 'THRUPLAY'
                        },
                    ]
                }
            };

            if (objective) {
                this.goals = goalMappings[this.platform][objective.platform_value];
                this.goal = goalMappings[this.platform][objective.platform_value][0];
            }
        },
        setBillingEvents() {
            this.billingEvents = [];
            this.billingEvent = [];

            const billingEventMapping = {
                'snapchat': {
                    'IMPRESSIONS': [
                        {
                            ui_name: 'Impressions',
                            platform_value: 'IMPRESSIONS'
                        },
                    ],
                    'SWIPES': [
                        {
                            ui_name: 'Swipes',
                            platform_value: 'SWIPES'
                        },
                    ],
                    'VIDEO_VIEWS_15_SEC': [
                        {
                            ui_name: 'Video Views 15 Seconds',
                            platform_value: 'VIDEO_VIEWS_15_SEC'
                        },
                        {
                            ui_name: 'Impression',
                            platform_value: 'IMPRESSION'
                        },
                    ],
                    'VIDEO_VIEWS': [
                        {
                            ui_name: 'Video Views',
                            platform_value: 'VIDEO_VIEWS'
                        },
                        {
                            ui_name: 'Impression',
                            platform_value: 'IMPRESSION'
                        },
                    ],
                    'PIXEL_PAGE_VIEW': [
                        {
                            ui_name: 'Pixel Page View',
                            platform_value: 'PIXEL_PAGE_VIEW'
                        },
                    ],
                },
                'tiktok': {
                    'REACH': [
                        {
                            ui_name: 'Cost Per Thousand',
                            platform_value: 'CPM'
                        },
                    ],
                    'CLICK': [
                        {
                            ui_name: 'Cost Per Click',
                            platform_value: 'CPC'
                        },
                    ],
                    'VIDEO_VIEW': [
                        {
                            ui_name: 'Cost Per View',
                            platform_value: 'CPV'
                        },
                    ],
                },
                'facebook': {
                    'VIDEO_VIEWS': [
                        {
                            ui_name: 'Video View',
                            platform_value: 'VIDEO_VIEW'
                        },
                        {
                            ui_name: 'Impressions',
                            platform_value: 'IMPRESSIONS'
                        },
                    ],
                    'THRUPLAY': [
                        {
                            ui_name: 'Thruplay',
                            platform_value: 'THRUPLAY'
                        },
                        {
                            ui_name: 'Impressions',
                            platform_value: 'IMPRESSIONS'
                        },
                    ],
                    'IMPRESSIONS': [
                        {
                            ui_name: 'Impressions',
                            platform_value: 'IMPRESSIONS'
                        },
                    ],
                    'POST_ENGAGEMENT': [
                        {
                            ui_name: 'Impressions',
                            platform_value: 'IMPRESSIONS'
                        },
                    ],
                    'LANDING_PAGE_VIEWS': [
                        {
                            ui_name: 'Impressions',
                            platform_value: 'IMPRESSIONS'
                        },
                    ],
                    'LINK_CLICKS': [
                        {
                            ui_name: 'Link Clicks',
                            platform_value: 'LINK_CLICKS'
                        },
                        {
                            ui_name: 'Impressions',
                            platform_value: 'IMPRESSIONS'
                        },
                    ],
                    'REACH': [
                        {
                            ui_name: 'Reach',
                            platform_value: 'REACH'
                        },
                    ],
                    'BRAND_AWARENESS': [
                        {
                            ui_name: 'Impressions',
                            platform_value: 'IMPRESSIONS'
                        },
                    ],
                    'AD_RECALL_LIFT': [
                        {
                            ui_name: 'Impressions',
                            platform_value: 'IMPRESSIONS'
                        },
                    ]
                }
            };

            if (this.goal) {
                this.billingEvents = billingEventMapping[this.platform][this.goal.platform_value];
                this.billingEvent = billingEventMapping[this.platform][this.goal.platform_value][0];
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.settings{
    padding: 0 30px 30px 30px;
}
.section-title {
    font-size: 16px;
    background: #f3f5f6;
    font-weight: 600;
    margin-left: -30px;
    margin-right: -30px;
    padding: 5px 0;
    padding-left: 30px;
}
.field{
    margin-top: 30px;
}
.type-title{
    display: block;
    margin-top: 30px;
}
.radius-wrapper{
    position: relative;
}
.coordinates{
    position: absolute;
    right:0;
    top:10px;
}
.radius{
    max-width: 200px;
}
</style>

<style lang="scss">
.v-autocomplete .v-label{
    text-transform: none;
}
.v-text-field__suffix {
    padding-right: 4px;
}
</style>
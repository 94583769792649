<template>
    <div>
        <h4 class="type-title">
            Budget Type
        </h4>
        <div class="radiobuttons">
            <v-radio-group
                v-model="choice"
                :mandatory="true"
                row>
                <v-radio
                    label="Daily"
                    value="Daily"
                    color="primary" />
                <v-radio
                    label="Lifetime"
                    value="Lifetime"
                    color="primary" />
            </v-radio-group>
            <v-text-field
                v-model="budget"
                pattern="[0-9]"
                required
                prefix="$"
                type="number"
                :rules="[...requiredRules, ...externalRules]"
                :label="choice+' Budget'"
                class="styled-field"
                @input="setBudget" />
        </div>
    </div>
</template>

<script>
import { requiredRules } from '@/helpers/validationRules';

export default {
    name: 'BudgetSelection',
    props: {
        platform: {
            type: String,
            required: true
        },
        budgetChoice: {
            type: String,
            required: true
        },
        externalRules: {
            type: Array,
            default() {
                return [];
            }
        }
    },
    data() {
        return {
            choice: '',
            budget: 0,
            requiredRules
        };
    },
    watch: {
        choice() {
            this.$emit('change-choice', this.choice);
        },
        budget() {
            this.$emit('set-budget', this.budget);
        }
    },
    mounted() {
        this.choice = this.budgetChoice;
    },
    methods: {
        setBudget() {
            this.$emit('set-budget', this.budget);
            this.$emit('clear-errors');
        }
    }
};
</script>

<style lang="scss" scoped>
.radiobuttons{
    display:inline-flex;
    align-items: center;
}
.radiobuttons > div{
    display: inline-block;
}
</style>

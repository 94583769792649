<template>
    <v-layout>
        <v-flex
            xs12
            md8
            offset-md2
            mt-5>
            <v-card
                light
                class="wrap-content">
                <v-toolbar
                    dark
                    color="white"
                    class="first-line-toolbar">
                    <h2 class="primary--text font-weight-regular mb-3 pl-2 pt-4">
                        Omni Ad Deployment Tool
                    </h2>
                    <v-spacer />
                </v-toolbar>
                <v-card-text>
                    <v-container
                        fluid
                        grid-list-md>
                        <div v-if="loading">
                            <loader />
                        </div>
                        <div v-else>
                            <v-stepper
                                v-model="step"
                                alt-labels
                                light>
                                <v-stepper-header>
                                    <v-stepper-step
                                        step="1"
                                        :complete="step > 1">
                                        CHANNELS
                                    </v-stepper-step>
                                    <v-divider />
                                    <v-stepper-step
                                        step="2"
                                        :complete="step > 2">
                                        CAMPAIGNS
                                    </v-stepper-step>
                                    <v-divider />
                                    <v-stepper-step
                                        step="3"
                                        :complete="step > 3">
                                        AD REVIEW
                                    </v-stepper-step>
                                    <v-divider />
                                    <v-stepper-step
                                        step="4"
                                        :complete="step > 4">
                                        DEPLOYMENT
                                    </v-stepper-step>
                                </v-stepper-header>
                                <v-stepper-items>
                                    <!-- STEP 1 -->
                                    <v-stepper-content step="1">
                                        <v-layout
                                            row
                                            class="px-5 py-1">
                                            <div class="checkboxes">
                                                <!-- pinterest checkbox -->
                                                <!-- <div class="flex-between">
                                                    <div class="pr-2 mt-3">
                                                        <platform-icons :platforms="['pinterest']" />
                                                    </div>
                                                    <div class="pr-2">
                                                        <styled-checkbox
                                                            class="checkbox"
                                                            :value="pinterest"
                                                            :disabled="notEligibleToDeploy('pinterest', pinterestIncompleteFeatures)"
                                                            @change="addChannel($event)" />
                                                    </div>
                                                    <div class="pr-2 mt-3 font" :class="notEligibleToDeploy('pinterest', pinterestIncompleteFeatures) ? 'disabled' : ''">
                                                        <div>Pinterest</div>
                                                    </div>
                                                </div>
                                                <div
                                                    v-if="!pinterest || pinterestIncompleteFeatures.length != 0"
                                                    class="not-setup"
                                                    @mouseover="hoverActive('pinterest', true)"
                                                    @mouseleave="hoverActive('pinterest', false)">
                                                    <span v-if="pinterestIncompleteFeatures.length == 0">Not Setup</span>
                                                    <span
                                                        v-else
                                                        class="miss-deps">
                                                        Missing Dependencies
                                                        <div
                                                            v-if="hovers.pinterest"
                                                            class="popup">
                                                            <ul>
                                                                <li
                                                                    v-for="feature in pinterestIncompleteFeatures"
                                                                    :key="feature.id">
                                                                    {{ feature.display_name }}
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </span>
                                                    <router-link
                                                        class="setup-button"
                                                        :to="{ name: 'channel-setup-product-selection', params: { dealer_id: dealer.id, channel: 'pinterest' }}">
                                                        <span
                                                            v-if="pinterestIncompleteFeatures.length != 0"
                                                            class="mr-1">FINISH</span> SETUP
                                                    </router-link>
                                                </div> -->

                                                <!--snapchat checkbox-->
                                                <div class="flex-between">
                                                    <div class="pr-2 mt-3">
                                                        <platform-icons :platforms="['snapchat']" />
                                                    </div>
                                                    <div class="pr-2">
                                                        <styled-checkbox
                                                            class="checkbox"
                                                            :value="snapchat"
                                                            :disabled="notEligibleToDeploy('snapchat', snapchatIncompleteFeatures)"
                                                            @change="addChannel($event)" />
                                                    </div>
                                                    <div
                                                        class="pr-2 mt-3 font"
                                                        :class="notEligibleToDeploy('snapchat', snapchatIncompleteFeatures) ? 'disabled' : ''">
                                                        <div>Snapchat</div>
                                                    </div>
                                                </div>
                                                <div
                                                    v-if="!snapchat || snapchatIncompleteFeatures.length != 0"
                                                    class="not-setup"
                                                    @mouseover="hoverActive('snapchat', true)"
                                                    @mouseleave="hoverActive('snapchat', false)">
                                                    <span v-if="snapchatIncompleteFeatures.length == 0">Not Setup</span>
                                                    <span
                                                        v-else
                                                        class="miss-deps">
                                                        Missing Dependencies
                                                        <div
                                                            v-if="hovers.snapchat"
                                                            class="popup">
                                                            <ul>
                                                                <li
                                                                    v-for="feature in snapchatIncompleteFeatures"
                                                                    :key="feature.id">
                                                                    {{ feature.display_name }}
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </span>
                                                    <router-link
                                                        class="setup-button"
                                                        :to="{ name: 'channel-setup-product-selection', params: { dealer_id: dealer.id, channel: 'snapchat' }}">
                                                        <span
                                                            v-if="snapchatIncompleteFeatures.length != 0"
                                                            class="mr-1">FINISH</span> SETUP
                                                    </router-link>
                                                </div>

                                                <!--tiktok checkbox-->
                                                <div class="flex-between">
                                                    <div class="pr-2 mt-3">
                                                        <platform-icons :platforms="['tiktok']" />
                                                    </div>
                                                    <div class="pr-2">
                                                        <styled-checkbox
                                                            class="checkbox"
                                                            :value="tiktok"
                                                            :disabled="notEligibleToDeploy('tiktok', tiktokIncompleteFeatures)"
                                                            @change="addChannel($event)" />
                                                    </div>
                                                    <div
                                                        class="pr-2 mt-3 font"
                                                        :class="notEligibleToDeploy('tiktok', tiktokIncompleteFeatures) ? 'disabled' : ''">
                                                        <div>TikTok</div>
                                                    </div>
                                                </div>
                                                <div
                                                    v-if="!tiktok && !tiktokRestricted || tiktokIncompleteFeatures.length != 0"
                                                    class="not-setup"
                                                    @mouseover="hoverActive('tiktok', true)"
                                                    @mouseleave="hoverActive('tiktok', false)">
                                                    <span v-if="tiktokIncompleteFeatures.length == 0">Not Setup</span>
                                                    <span
                                                        v-else
                                                        class="miss-deps">
                                                        Missing Dependencies
                                                        <div
                                                            v-if="hovers.tiktok"
                                                            class="popup">
                                                            <ul>
                                                                <li
                                                                    v-for="feature in tiktokIncompleteFeatures"
                                                                    :key="feature.id">
                                                                    {{ feature.display_name }}
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </span>
                                                    <router-link
                                                        class="setup-button"
                                                        :to="{ name: 'channel-setup-product-selection', params: { dealer_id: dealer.id, channel: 'tiktok' }}">
                                                        <span
                                                            v-if="tiktokIncompleteFeatures.length != 0"
                                                            class="mr-1">FINISH</span> SETUP
                                                    </router-link>
                                                </div>
                                                <div
                                                    v-if="tiktokRestricted && tiktokIncompleteFeatures.length == 0"
                                                    class="not-setup">
                                                    <span>You have no Video Ads selected</span>
                                                </div>
                                                <div
                                                    v-if="tiktok && tiktokIncompleteFeatures.length == 0"
                                                    class="not-setup">
                                                    <span>Only Video Ads will be deployed</span>
                                                </div>

                                                <!-- facebook checkbox -->
                                                <div class="flex-between">
                                                    <div class="pr-2 mt-3">
                                                        <platform-icons :platforms="['facebook']" />
                                                    </div>
                                                    <div class="pr-2">
                                                        <styled-checkbox
                                                            class="checkbox"
                                                            :value="facebook"
                                                            :disabled="notEligibleToDeploy('facebook', facebookIncompleteFeatures)"
                                                            @change="addChannel($event)" />
                                                    </div>
                                                    <div
                                                        class="pr-2 mt-3 font"
                                                        :class="notEligibleToDeploy('facebook', facebookIncompleteFeatures) ? 'disabled' : ''">
                                                        <div>Facebook</div>
                                                    </div>
                                                </div>
                                                <div
                                                    v-if="!facebook || facebookIncompleteFeatures.length != 0"
                                                    class="not-setup"
                                                    @mouseover="hoverActive('facebook', true)"
                                                    @mouseleave="hoverActive('facebook', false)">
                                                    <span v-if="facebookIncompleteFeatures.length == 0">Not Setup</span>
                                                    <span
                                                        v-else
                                                        class="miss-deps">
                                                        Missing Dependencies
                                                        <div
                                                            v-if="hovers.facebook"
                                                            class="popup">
                                                            <ul>
                                                                <li
                                                                    v-for="feature in facebookIncompleteFeatures"
                                                                    :key="feature.id">
                                                                    {{ feature.display_name }}
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </span>
                                                    <router-link
                                                        class="setup-button"
                                                        :to="{ name: 'channel-setup-product-selection', params: { dealer_id: dealer.id, channel: 'facebook' }}">
                                                        <span
                                                            v-if="facebookIncompleteFeatures.length != 0"
                                                            class="mr-1">FINISH</span> SETUP
                                                    </router-link>
                                                </div>
                                            </div>
                                        </v-layout>
                                        <v-layout
                                            class="py-5 ma-0 form-button"
                                            row
                                            justify-center
                                            align-center>
                                            <v-flex xs12>
                                                <div class="text-xs-center">
                                                    <div class="error-message">
                                                        {{ errorMessage }}
                                                    </div>
                                                    <v-btn
                                                        v-if="!loading"
                                                        flat
                                                        color="white"
                                                        large
                                                        @click="goToSecondStep">
                                                        Continue
                                                    </v-btn>
                                                </div>
                                            </v-flex>
                                        </v-layout>
                                    </v-stepper-content>
                                    <!-- END of STEP 1 -->

                                    <!-- STEP 2 -->
                                    <v-stepper-content step="2">
                                        <v-layout
                                            column
                                            class="px-5 py-1">
                                            <div v-if="activeChannels.has('facebook')">
                                                <h2 class="mb-2">
                                                    <icon
                                                        class="channel-icon"
                                                        color="blue"
                                                        name="facebook" />
                                                    Facebook
                                                </h2>
                                                <div class="form-wrapper">
                                                    <campaign-configuration
                                                        ref="facebookCampaign"
                                                        platform="facebook"
                                                        @existing-campaign-selection-updated="existingCampaignSelectionUpdated"
                                                        @provide-data="getCampaign"
                                                        @block-existing="blockAdset"
                                                        @objective-change="setCurrentObjective"
                                                        @clear-errors="clearErrorMessage" />
                                                </div>
                                                <div class="form-wrapper">
                                                    <adset-configuration
                                                        ref="facebookAdset"
                                                        platform="facebook"
                                                        :cant-use-existing="adsetAllowed.facebook"
                                                        :existing-selected-campaign="existingSelectedCampaign"
                                                        :objective="facebookObjective"
                                                        @provide-data="getAdset"
                                                        @clear-errors="clearErrorMessage" />
                                                </div>
                                            </div>
                                            <div v-if="activeChannels.has('snapchat')">
                                                <h2 class="mb-2">
                                                    <icon
                                                        class="channel-icon"
                                                        color="yellow"
                                                        name="snapchat" />
                                                    <span class="yellow-color"> Snapchat</span>
                                                </h2>
                                                <div class="form-wrapper">
                                                    <campaign-configuration
                                                        ref="snapchatCampaign"
                                                        platform="snapchat"
                                                        @existing-campaign-selection-updated="existingCampaignSelectionUpdated"
                                                        @provide-data="getCampaign"
                                                        @block-existing="blockAdset"
                                                        @objective-change="setCurrentObjective"
                                                        @clear-errors="clearErrorMessage" />
                                                </div>
                                                <div class="form-wrapper">
                                                    <adset-configuration
                                                        ref="snapchatAdset"
                                                        platform="snapchat"
                                                        :cant-use-existing="adsetAllowed.snapchat"
                                                        :existing-selected-campaign="existingSelectedCampaign"
                                                        :objective="snapchatObjective"
                                                        @provide-data="getAdset"
                                                        @clear-errors="clearErrorMessage" />
                                                </div>
                                            </div>
                                            <div v-if="activeChannels.has('pinterest')">
                                                <h2 class="mb-2">
                                                    <icon
                                                        class="channel-icon"
                                                        color="red"
                                                        name="pinterest" />
                                                    <span class="red-color"> Pinterest</span>
                                                </h2>
                                                <div class="form-wrapper">
                                                    <campaign-configuration
                                                        ref="pinterestCampaign"
                                                        platform="pinterest"
                                                        @existing-campaign-selection-updated="existingCampaignSelectionUpdated"
                                                        @provide-data="getCampaign"
                                                        @block-existing="blockAdset"
                                                        @objective-change="setCurrentObjective"
                                                        @clear-errors="clearErrorMessage" />
                                                </div>
                                                <div class="form-wrapper">
                                                    <adset-configuration
                                                        ref="pinterestAdset"
                                                        platform="pinterest"
                                                        :cant-use-existing="adsetAllowed.pinterest"
                                                        :existing-selected-campaign="existingSelectedCampaign"
                                                        :objective="pinterestObjective"
                                                        @provide-data="getAdset"
                                                        @clear-errors="clearErrorMessage" />
                                                </div>
                                            </div>
                                            <div v-if="activeChannels.has('tiktok')">
                                                <h2 class="mb-2">
                                                    <icon
                                                        class="channel-icon"
                                                        color="grayDark"
                                                        name="tiktok" />
                                                    <span class="graydark-color"> Tiktok</span>
                                                </h2>
                                                <div class="form-wrapper">
                                                    <campaign-configuration
                                                        ref="tiktokCampaign"
                                                        platform="tiktok"
                                                        @existing-campaign-selection-updated="existingCampaignSelectionUpdated"
                                                        @provide-data="getCampaign"
                                                        @block-existing="blockAdset"
                                                        @objective-change="setCurrentObjective"
                                                        @clear-errors="clearErrorMessage" />
                                                </div>
                                                <div class="form-wrapper">
                                                    <adset-configuration
                                                        ref="tiktokAdset"
                                                        platform="tiktok"
                                                        :cant-use-existing="adsetAllowed.tiktok"
                                                        :existing-selected-campaign="existingSelectedCampaign"
                                                        :objective="tiktokObjective"
                                                        @provide-data="getAdset"
                                                        @clear-errors="clearErrorMessage" />
                                                </div>
                                            </div>
                                        </v-layout>
                                        <v-layout
                                            class="pb-5 ma-0 form-button"
                                            row
                                            justify-center
                                            align-center>
                                            <v-flex xs12>
                                                <div class="text-xs-center">
                                                    <div class="error-message">
                                                        {{ errorMessage }}
                                                    </div>
                                                    <v-btn
                                                        flat
                                                        large
                                                        color="white"
                                                        @click="submitSecondStep">
                                                        Continue
                                                    </v-btn>
                                                </div>
                                            </v-flex>
                                        </v-layout>
                                    </v-stepper-content>
                                    <!-- END of STEP 2 -->

                                    <!-- STEP 3 -->
                                    <v-stepper-content
                                        v-if="step === 3"
                                        step="3">
                                        <v-layout
                                            v-if="creatives.length"
                                            column
                                            class="px-5 py-1">
                                            <div
                                                v-for="(creative, index) in creatives"
                                                :key="index"
                                                class="form-wrapper">
                                                <ad-deployment
                                                    ref="creatives"
                                                    :creative-ad="creative"
                                                    :active-channels="activeChannels"
                                                    :on-review="true"
                                                    @deploy-ads="getAdData"
                                                    @clear-errors="clearErrorMessage" />
                                            </div>
                                        </v-layout>
                                        <v-layout
                                            class="ma-0 form-button"
                                            row
                                            justify-center
                                            align-center>
                                            <v-flex xs12>
                                                <div class="text-xs-center">
                                                    <div class="error-message">
                                                        {{ errorMessage }}
                                                    </div>
                                                    <v-btn
                                                        flat
                                                        large
                                                        color="white"
                                                        @click="submitAllCreatives">
                                                        Continue
                                                    </v-btn>
                                                </div>
                                            </v-flex>
                                        </v-layout>
                                    </v-stepper-content>
                                    <!-- END of STEP 3 -->

                                    <!-- STEP 4 -->
                                    <v-stepper-content step="4">
                                        <v-layout
                                            column
                                            justify-center
                                            align-start
                                            class="px-5 py-1">
                                            <div
                                                v-if="activeChannels.has('facebook')"
                                                class="progress-wrapper mb-5">
                                                <icon
                                                    class="channel-icon absolute-icon"
                                                    color="blue"
                                                    name="facebook" />
                                                <div>
                                                    <v-progress-linear
                                                        color="primary"
                                                        height="28"
                                                        :value="total"
                                                        class="mb-2" />
                                                    <div
                                                        class="progress-info">
                                                        <div><strong>Status:</strong> {{ facebookDeploymentMessages }}</div>
                                                        <div>{{ total }}% complete</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                v-if="activeChannels.has('pinterest')"
                                                class="progress-wrapper mb-5">
                                                <icon
                                                    class="channel-icon absolute-icon"
                                                    color="red"
                                                    name="pinterest" />
                                                <div>
                                                    <v-progress-linear
                                                        color="primary"
                                                        height="28"
                                                        :value="total"
                                                        class="mb-2" />
                                                    <div
                                                        class="progress-info">
                                                        <div><strong>Status:</strong> {{ pinterestDeploymentMessages }}</div>
                                                        <div>{{ total }}% complete</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                v-if="activeChannels.has('snapchat')"
                                                class="progress-wrapper mb-5">
                                                <icon
                                                    class="channel-icon absolute-icon"
                                                    color="yellow"
                                                    name="snapchat" />
                                                <div>
                                                    <v-progress-linear
                                                        color="primary"
                                                        height="28"
                                                        :value="total"
                                                        class="mb-2" />
                                                    <div
                                                        class="progress-info">
                                                        <div><strong>Status:</strong> {{ snapchatDeploymentMessages }}</div>
                                                        <div>{{ total }}% complete</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                v-if="activeChannels.has('tiktok')"
                                                class="progress-wrapper mb-5">
                                                <icon
                                                    class="channel-icon absolute-icon"
                                                    color="grayDark"
                                                    name="tiktok" />
                                                <div>
                                                    <v-progress-linear
                                                        color="primary"
                                                        height="28"
                                                        :value="totalTiktok"
                                                        class="mb-2" />
                                                    <div
                                                        class="progress-info">
                                                        <div><strong>Status:</strong> {{ tiktokDeploymentMessages }}</div>
                                                        <div>{{ totalTiktok }}% complete</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </v-layout>
                                    </v-stepper-content>
                                    <!-- END of STEP 4 -->
                                </v-stepper-items>
                            </v-stepper>
                        </div>
                    </v-container>
                </v-card-text>
            </v-card>
        </v-flex>
    </v-layout>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex';
import StyledCheckbox from '@/components/globals/StyledCheckbox';
import AdDeployment from '@/components/creative-library/AdDeployment';
import CampaignConfiguration from '@/components/creative-library/CampaignConfiguration';
import AdsetConfiguration from '@/components/creative-library/AdsetConfiguration';
import Icon from '@/components/globals/Icon';
import Loader from '@/components/globals/Loader.vue';
import PlatformIcons from '@/components/creative-library/PlatformIcons.vue';
import { hasEligibleAssetsForPlatform } from '@/helpers/platformAspectRatios.js';
import {
    FACEBOOK_FEATURES,
    SNAPCHAT_FEATURES,
    TIKTOK_FEATURES
} from '@/helpers/globals';

export default {
    title: 'Omni Ad Deployment Tool',
    components: {
        StyledCheckbox,
        AdDeployment,
        CampaignConfiguration,
        AdsetConfiguration,
        Icon,
        Loader,
        PlatformIcons
    },
    data() {
        return {
            facebookIncompleteFeatures: [],
            tiktokIncompleteFeatures: [],
            snapchatIncompleteFeatures: [],
            pinterestIncompleteFeatures: [],
            hovers: {
                facebook: false,
                pinterest: false,
                tiktok: false,
                snapchat: false,
            },
            step: null,
            loading: false,
            errorMessage: '',
            activeChannels: new Set(),
            pinterest: '',
            snapchat: '',
            tiktok: '',
            tiktokRestricted: false,
            facebook: '',
            submittedCreatives: [],
            submittedData: {},
            adsetAllowed: {
                pinterest: true,
                snapchat: true,
                tiktok: true,
                facebook: true,
            },
            facebookDeploymentMessages: '',
            snapchatDeploymentMessages: '',
            tiktokDeploymentMessages: '',
            pinterestDeploymentMessages: '',
            total: 0,
            totalTiktok: 0,
            existingSelectedCampaign: {},
            facebookObjective: [],
            snapchatObjective: [],
            tiktokObjective: [],
            pinterestObjective: []
        };
    },
    computed: {
        ...mapGetters([
            'dealerPlatforms',
            'dealerChangedStatus',
            'dealerFeaturesIncomplete',
        ]),
        ...mapState({
            dealer: state => state.dealer.currentDealer,
            creatives: state => state.creativeLibrary.creativesForDeployment
        })
    },
    watch: {
        dealerChangedStatus() {
            if (this.dealerChangedStatus === true) {
                this.loading = true;
            } else {
                this.step = '1';
                this.loading = false;
                this.populateChannels();
                this.$router.push({ name: 'creative-library' });
            }
        },
        creatives() {
            this.restrictTiktok();
        },
        dealer() {
            this.checkValidFeatures();
        },
    },
    mounted() {
        this.loading = true;
        this.checkForCreatives();
        this.populateChannels();
        this.updateCurrentDealer();
    },
    methods: {
        ...mapActions([
            'updateCurrentDealer',
        ]),
        async checkValidFeatures() {
            this.loading = true;
            const validFeatures = [];
            validFeatures.push(...FACEBOOK_FEATURES, ...SNAPCHAT_FEATURES, ...TIKTOK_FEATURES);
            await this.$store.dispatch('updateDealerFeatureStatus', {
                features: validFeatures
            });
            //simple checking if feature has right prefix
            this.facebookIncompleteFeatures = this.dealerFeaturesIncomplete.filter(f => {
                return f.feature.indexOf('facebook') > -1;
            });
            this.snapchatIncompleteFeatures = this.dealerFeaturesIncomplete.filter(f => {
                return f.feature.indexOf('snapchat') > -1;
            });
            this.tiktokIncompleteFeatures = this.dealerFeaturesIncomplete.filter(f => {
                return f.feature.indexOf('tiktok') > -1;
            });
            this.pinterestIncompleteFeatures = this.dealerFeaturesIncomplete.filter(f => {
                return f.feature.indexOf('pinterest') > -1;
            });
            this.loading = false;
        },
        hoverActive(channel, val) {
            channel == 'facebook' && val ? this.hovers.facebook = true : this.hovers.facebook = false;
            channel == 'snapchat' && val ? this.hovers.snapchat = true : this.hovers.snapchat = false;
            channel == 'pinterest' && val ? this.hovers.pinterest = true : this.hovers.pinterest = false;
            channel == 'tiktok' && val ? this.hovers.tiktok = true : this.hovers.tiktok = false;
        },
        restrictTiktok() {
            const creativeHasVideo = this.creatives.filter(creative => creative.configuration.render_type === 'Video');
            if (!creativeHasVideo.length) {
                this.tiktokRestricted = true;
                this.tiktok = '';
            } else {
                this.tiktokRestricted = false;
                this.tiktok = 'tiktok';
            }
        },
        hasChannel(value) {
            return value === '';
        },
        addChannel(event) {
            this.clearErrorMessage();
            if (event.target.checked) {
                this.activeChannels.add(event.target.value);
            } else {
                this.activeChannels.delete(event.target.value);
            }
        },
        populateChannels() {
            this.pinterest = this.snapchat = this.tiktok = this.facebook = '';
            this.dealerPlatforms.forEach(channel => {
                if (channel.name === 'pinterest') this.pinterest = channel.name;
                if (channel.name === 'snapchat') this.snapchat = channel.name;
                if (channel.name === 'tiktok') this.tiktok = channel.name;
                if (channel.name === 'facebook') this.facebook = channel.name;
            });
            this.restrictTiktok();
        },
        getAdData(creative) {
            this.submittedCreatives.push(creative);
        },
        getCampaign(campaign, platform) {
            Object.assign(this.submittedData[platform].campaigns, campaign);
        },
        getAdset(adset, platform) {
            Object.assign(this.submittedData[platform].adsets, adset);
        },
        submitAllCreatives() {
            this.submittedCreatives = []; // clear array if we have some errors and need to restart
            for (let i = 0; i < this.creatives.length; i++) {
                this.$refs.creatives[i].submit();
            }
            if (this.submittedCreatives.length !== this.creatives.length) {
                this.errorMessage = 'Please check all your ads - it seems that some information is missing!';
            } else {
                this.step++;
                this.submittingProcess();
            }
        },
        listenForPlayBuildingMessages(platformMessagesVariableName, uuid) {
            this.$echo.channel(`play-build-channel-${uuid}`)
                .listen('PlayBuilderEvent', (e) => {
                    this[platformMessagesVariableName] = e.message;
                });
        },
        submittingProcess() {
            this.buildAdData();
        },
        buildAdData() {
            if (this.submittedData?.snapchat) {
                this.deployForPlatform('snapchat');
            }

            if (this.submittedData?.facebook) {
                this.deployForPlatform('facebook');
            }

            if (this.submittedData?.pinterest) {
                this.deployForPlatform('pinterest');
            }

            if (this.submittedData?.tiktok) {
                this.deployForPlatform('tiktok');
            }
        },
        async deployForPlatform(platformName) {
            if (this.submittedData[platformName]) {
                try {
                    const response = await this.$apiRepository.deployAdToPlatform(this.dealer.id, platformName, { play: { campaigns: this.buildAdStructure(this.submittedData[platformName], platformName) } });

                    if (response.data.uuid) {
                        this.listenForPlayBuildingMessages(`${platformName}DeploymentMessages`, response.data.uuid);
                    }
                } catch (e) {
                    console.log(`Error building ${platformName} ads`);
                    console.log(e);
                }
            }
        },
        buildAdSetBudget(campaignBudget, adSetBudget, platformName) {
            if (platformName === 'facebook') {
                return {
                    daily_budget: adSetBudget.daily_budget ?? null,
                    daily_spend_cap: adSetBudget.daily_spend_cap ?? null,
                    lifetime_budget: adSetBudget.lifetime_budget ?? null,
                    lifetime_spend_cap: adSetBudget.lifetime_spend_cap ?? null,
                    currency: adSetBudget.currency ?? null,
                    period: adSetBudget.period ?? null,
                    start_date: adSetBudget.start_date ?? null,
                    end_date: adSetBudget.end_date ?? null,
                };
            } else {
                return { // Snapchat and TikTok map the campaign data to the adset level
                    daily_budget: adSetBudget.daily_budget ?? campaignBudget.daily_budget ?? null,
                    daily_spend_cap: adSetBudget.daily_spend_cap ?? campaignBudget.daily_spend_cap ?? null,
                    lifetime_budget: adSetBudget.lifetime_budget ?? campaignBudget.lifetime_budget ?? null,
                    lifetime_spend_cap: adSetBudget.lifetime_spend_cap ?? campaignBudget.lifetime_spend_cap ?? null,
                    currency: adSetBudget.currency ?? campaignBudget.currency ?? null,
                    period: adSetBudget.period ?? campaignBudget.period ?? null,
                    start_date: adSetBudget.start_date ?? campaignBudget.start_date ?? null,
                    end_date: adSetBudget.end_date ?? campaignBudget.end_date ?? null,
                };
            }
        },
        buildAdStructure(data, platformName) { // todo refactor this to be much more flexible and readable
            let campaigns =  [
                data.campaigns,
            ];
            campaigns.map((item, campaignIndex) => {
                campaigns[campaignIndex].ad_sets = [data.adsets];
                campaigns[campaignIndex].ad_sets.map((item, adSetIndex) => {
                    if (campaigns[campaignIndex].budget && campaigns[campaignIndex].ad_sets[adSetIndex].budget) {
                        // Check to see if an ad set budget exists and merge it with the campaign object if it does
                        campaigns[campaignIndex].ad_sets[adSetIndex].budget = this.buildAdSetBudget(campaigns[campaignIndex].budget, campaigns[campaignIndex].ad_sets[adSetIndex].budget, platformName);
                    }
                    campaigns[campaignIndex].ad_sets[adSetIndex].ads = this.submittedCreatives;
                });
            });
            return campaigns;
        },
        submitSecondStep() {
            this.makeDataStructure();
            this.activeChannels.forEach(channel => {
                this.$refs[channel+'Campaign'].submit();
                this.$refs[channel+'Adset'].submit();
            });
            const allCampaigns = [];
            const allAdsets = [];
            Object.values(this.submittedData).forEach(data => {
                if (Object.keys(data.campaigns).length) {
                    allCampaigns.push(data.campaigns);
                }
                if (Object.keys(data.adsets).length) {
                    allAdsets.push(data.adsets);
                }
            });
            if (allCampaigns.length !== this.activeChannels.size || allAdsets.length !== this.activeChannels.size) {
                this.errorMessage = 'Please check all your data - it seems that some information is missing!';
            } else {
                this.errorMessage = '';
                console.log(this.submittedData);
                this.step++;
            }
        },
        clearErrorMessage() {
            this.errorMessage = '';
        },
        makeDataStructure() {
            let channelData;
            this.activeChannels.forEach(channel => {
                channelData = {
                    [channel]: {
                        campaigns: {},
                        adsets: {}
                    }
                };
                Object.assign(this.submittedData, channelData);
            });
        },
        goToSecondStep() {
            if (this.activeChannels.size === 0) {
                this.errorMessage = 'Please select at least one channel';
            } else {
                this.errorMessage = '';
                this.makeDataStructure();
                this.step++;
            }
        },
        blockAdset(value, platform) {
            this.adsetAllowed[platform] = value;
        },
        checkForCreatives() {
            if (!this.$store.state.creativeLibrary.creativesForDeployment.length) {
                this.$router.push({ name: 'creative-library' });
            }
        },
        existingCampaignSelectionUpdated(updatedCampaign) {
            this.existingSelectedCampaign = updatedCampaign;
        },
        setCurrentObjective(currentObjective, platform) {
            if (platform == 'facebook') {
                this.facebookObjective = currentObjective;
            }
            if (platform == 'tiktok') {
                this.tiktokObjective = currentObjective;
            }
            if (platform == 'snapchat') {
                this.snapchatObjective = currentObjective;
            }
            if (platform == 'pinterest') {
                this.pinterestObjective = currentObjective;
            }
        },
        hasEligibleAssets(platform) {
            return hasEligibleAssetsForPlatform(this.creatives[0]?.full_configuration?.creatives[0]?.assets, platform);
        },
        notEligibleToDeploy(platform, incompletePlatformFeatures) {
            return this.hasChannel(platform) || incompletePlatformFeatures.length != 0 || !this.hasEligibleAssets(platform);
        }
    }
};
</script>

<style lang="scss" scoped>
.checkboxes{
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    padding-top: 30px;
    padding-left: 60px;
    label{
        margin-top: 15px;
        font-size: 16px;
        align-items: center;
    }
}
.not-setup{
    padding-left: 28px;
    > span{
        color: $gray-light;
        text-decoration: underline;
        margin-right: 15px;
    }
}
.setup-button{
    text-decoration: none;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background: $blue-bg;
    color: $white;
    font-weight: 600;
    border-radius: 5px;
    height: 25px;
    padding: 0 15px;
}
.form-wrapper{
    background: #FAFBFC;
    border: 1px solid #D9DADA;
    margin-bottom: 60px;
}
.error-message{
    color:red;
    font-size: 16px;
    margin-bottom: 5px;
}
.yellow-color{
    color: #f8c41c;
}
.red-color{
    color: #f6685f;
}
.graydark-color{
    color: #4e555b;
}
.progress-info{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.progress-wrapper{
    position: relative;
    width: 100%;
    padding-left: 30px;
}
.absolute-icon{
    position: absolute;
    left:-15px;
    top:15px;
}
.miss-deps{
    position: relative;
    .popup{
        width: 280px;
        text-align: left;
        position: absolute;
        box-shadow: 1px 1px 10px 1px rgba(0,0,0,0.25);
        padding: 15px;
        bottom: calc(100% + 25px);
        left: 50%;
        transform: translateX(-50%);
        background: white;
        color: black;
        box-sizing: border-box;
        z-index: 999;
        &:after{
            content: '';
            position: absolute;
            width: 26px;
            height: 26px;
            transform: translateX(-50%) rotate(45deg) skew(-10deg, -10deg);
            bottom: -13px;
            left: 50%;
            background: white;
            box-shadow: 1px 1px 10px 1px rgba(0,0,0,0.25);
        }
        &:before{
            content: '';
            position: absolute;
            background: white;
            width: 100px;
            height: 20px;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            z-index: 3;
        }
    }
}
.channel-icon {
    width: 35px;
    height: auto;
}
.flex-between {
    display: flex;
    align-items: center;
}
.checkbox {
    display: flex;
    align-items: center;
    .checkboxes {
        label {
            margin-top: 0px;
        }
    }
}
.font {
    font-size: 16px;
}
.disabled {
    color: lightgray;
}
</style>
